import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import * as _ from 'lodash';

export interface UpdateMailDialogDataInterface {
    updateMail: (mail: string | null) => Observable<{error: string} | null>;
}

@Component({
    selector: 'app-update-mail-dialog',
    templateUrl: './update-mail-dialog.component.html',
})
export class UpdateMailDialogComponent {
    public mailFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
    public isLoading = false;
    public error: string;

    constructor(
        public dialogRef: MatDialogRef<UpdateMailDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) private data: UpdateMailDialogDataInterface,
    ) {
    }

    public onCancelClick($event: MouseEvent): void {
        this.dialogRef.close();
    }

    public onSaveClick($event: MouseEvent): void {
        this.isLoading = true;
        const result = this.mailFormControl.valid ? this.mailFormControl.value : null;
        this.mailFormControl.disable();
        this.data.updateMail(result).subscribe((data) => {
            if (_.has(data, 'error')) {
                this.error = data.error;
                this.mailFormControl.enable();
                this.isLoading = false;
            } else {
                this.dialogRef.close();
            }
        });
    }

    public canSave(): boolean {
        return this.isLoading === false && this.mailFormControl.valid;
    }
}