// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-tags-selection-core .mat-mdc-table .mat-mdc-header-row {
  display: none;
}
app-tags-selection-core .mat-toolbar {
  min-height: initial;
}
app-tags-selection-core .toolbar-bottom {
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/tags-selection/tags-selection-core/tags-selection-core.component.scss","webpack://./../APP%20Humanum/src/@fuse/components/tags-selection/tags-selection-core/tags-selection-core.component.scss"],"names":[],"mappings":"AAES;EACI,aAAA;ACDb;ADKK;EACI,mBAAA;ACHT;ADMK;EACI,YAAA;ACJT","sourcesContent":["app-tags-selection-core {\n     .mat-mdc-table {\n         .mat-mdc-header-row {\n             display: none;\n         }\n     }\n\n     .mat-toolbar {\n         min-height: initial;\n     }\n\n     .toolbar-bottom {\n         height: auto;\n     }\n }","app-tags-selection-core .mat-mdc-table .mat-mdc-header-row {\n  display: none;\n}\napp-tags-selection-core .mat-toolbar {\n  min-height: initial;\n}\napp-tags-selection-core .toolbar-bottom {\n  height: auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
