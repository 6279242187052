import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardComponent} from './card.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SharedTranslateModule} from '../../../app/shared/shared-translate.module';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {AssignModalComponent} from './assign-modal/assign-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {ButtonListModule} from 'fuse-core/components/button-list/button-list.module';
import { LessonCardBreadcrumbComponent } from './lesson-card-breadcrumb/lesson-card-breadcrumb.component';
import {RouterLink} from "@angular/router";
import {SharedMaterialModule} from 'shared/shared-material.module';

@NgModule({
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        MatChipsModule,
        SharedTranslateModule,
        MatSlideToggleModule,
        FuseSharedModule,
        MatDialogModule,
        MatInputModule,
        ButtonListModule,
        RouterLink,
        SharedMaterialModule,
    ],
    declarations: [CardComponent, AssignModalComponent, LessonCardBreadcrumbComponent],
    exports: [
        CardComponent
    ],
})
export class CardModule {
}