import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UpsellComponent} from './upsell/upsell.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
    declarations: [
        UpsellComponent
    ],
    imports: [
        CommonModule,
        MatSnackBarModule,
        TranslateModule,
        MatButtonModule
    ]
})
export class OnboardingModule {
}