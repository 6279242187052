import {GamificationService} from './../gamification.service';
import {DataEntity} from 'octopus-connect';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {tap} from 'rxjs/operators';
import {EventService} from '../../../../shared/event.service';

@Component({
    selector: 'app-preview-item-popup',
    templateUrl: './preview-item-popup.component.html'
})
export class PreviewItemPopupComponent implements OnInit {
    public isSelected: boolean;
    public isUnlocked: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public badge: DataEntity,
        private gamificationService: GamificationService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<PreviewItemPopupComponent>,
        private eventService: EventService
    ) {
    }

    ngOnInit(): void {
        this.isSelected = this.badge.get('selected');
        this.isUnlocked = this.badge.get('unLocked');
    }

    close(): void {
        this.dialogRef.close({isSelected: this.isSelected, badge: this.badge});
    }

    toggleBadge(action): void {
        console.log('toggleBadge', 'FDP');
        this.isSelected = !this.isSelected;
        if (!this.isUnlocked && this.userCoin > this.badge.get('price')) {
            this.isUnlocked = true;
            this.isSelected = true;
            this.eventService.trackEvent(
                'Avatar ' + action,
                action + ' ' +  this.badge.get('label') + ' #' + this.gamificationService.userData.id,
                'Gamification'
            );
        }
        setTimeout(() => this.close(), 500);
    }

    public get userCoin(): number {
        return this.gamificationService.userPoints || 0;
    }
}