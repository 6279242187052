// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export function buildApiUrl(brand?: string): string {
    return "https://preprod.api.humanum.tralalere.com/";
}

export function buildUrl(brand?: string): string {
    return 'https://preprod.lms.tralalere.com/' + (brand ? brand + '/' : '');
}

export function buildNodeUrl(brand?: string): string {
    return 'https://preprod.node.lms.tralalere.com/';
}
export function buildAppUrl(brand: string): string {
    return `https://preprod.app.${brand}.tralalere.com/`;
}

export function lrsAuthToken(): string {
    return `NDc0MjJhM2ZhZDExOTQzYTY1OWFiNDk0YmRkMzM4M2EwM2M3NzBkNjoyMDZjOGFkZWJlZjMwMGVkNTcyMzM1ZmRkYTdkY2I4YTM3NzA2YjIy`;
}

export const environment = {
    production: false,
    hmr: false,
    baseApiUrl: buildApiUrl,
    baseAppUrl: buildAppUrl,
    baseNodeUrl: buildNodeUrl,
    baseUrl: buildUrl,
    basePlatformPath: `https://preprod.humanum.tralalere.com/`,
    mindmapUrl: 'https://preprod.mindmap.tralalere.com/mindmap/',
};

export function getTrackingID(trackingId?: string): string {
    return 'G-WTQWL768HY'; // LMS Site vitrine dev tracking ID
}

export function getTrackingIDMatomo(trackingId?: string): string {
    return '3'; // default
}

/**
 * define type of front making calls to backend
 */
export function getFrontType(): string {
    return 'app'; // app or platform
}

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
