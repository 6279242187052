import {Component, EventEmitter, Input, Output} from '@angular/core';


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    @Output() navigate = new EventEmitter<void>();
    @Input() data = {mainClass: '', title: '', description: '', buttonText: ''};

    public onClick() {
        this.navigate.emit();
    }
}