import {Component} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-assignment-warning-modal',
    templateUrl: './assignment-warning-modal.component.html',
    styleUrls: ['./assignment-warning-modal.component.scss']
})
export class AssignmentWarningModalComponent {

    constructor(public selfDialogRef: MatDialogRef<AssignmentWarningModalComponent>) {
    }

    accept(): void {
        this.selfDialogRef.close(true);
    }

    cancel(): void {
        this.selfDialogRef.close(false);
    }
}