import {Component, Inject, Optional} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarDataInjectedInterface} from '../onboarding.service';

@Component({
    selector: 'app-upsell',
    templateUrl: './upsell.component.html',
    styleUrls: ['./upsell.component.scss']
})
export class UpsellComponent {

    constructor(private snackBar: MatSnackBar,
                @Optional() @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarDataInjectedInterface) {
    }

    public dismiss(): void {
        this.snackBar.dismiss();
    }

}