import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarDataInjectedInterface} from 'fuse-core/news/snackbar/snack-bar-data-injected.interface';

@Component({
    selector: 'app-new-learner-start-sub-lessons',
    templateUrl: './new-learner-start-sub-lessons.component.html',
    styleUrls: ['./new-learner-start-sub-lessons.component.scss']
})
export class NewLearnerStartSubLessonsComponent implements OnInit {

    constructor(private snackbar: MatSnackBar, @Optional() @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarDataInjectedInterface) {
    }

    onClick() {
        this.snackbar.dismiss();
    }

    ngOnInit(): void {
    }

}