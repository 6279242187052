import {takeUntil, tap} from 'rxjs/operators';
import {Component, OnInit, OnDestroy, inject} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {HeaderNavigationService} from '../service/header-navigation.service';
import {combineLatest, Subject} from 'rxjs';
import {defaultValue, brand, modulesSettings, langs} from '../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {Router, NavigationEnd} from '@angular/router';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {FuseSelectionDialogComponent} from 'fuse-core/components/selection-dialog/selection-dialog.component';
import {ConceptsService} from '@modules/activities/core/services/concepts.service';
import {
    EducationalLevelEntity,
    EducationalLevelService
} from '@modules/activities/core/services/educational-level.service';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {ConceptEntity} from '../../shared/models';
import {UserDataEntity} from '@modules/authentication';
import {HelpLessonsDialogComponent} from 'fuse-core/components/help-lessons-dialog/help-lessons-dialog.component';
import {HelpDictionaryDialogComponent} from 'fuse-core/components/helpdictionary-dialog/help-dictionary-dialog.component';

const settingsToolbar: ModelSchema = new ModelSchema({
    helpMenu: Structures.boolean(false),
    showWebAppBtn: Structures.boolean(false),
    translationAccess: Structures.object({show: false, withFlag: true}),
    notificationButtonAccess: Structures.boolean(),
    feedbackMenu: Structures.boolean(false),
    showTechnicalProblemMessage: Structures.boolean(false)
});

interface Lang {
    id: string,
    title: string,
    flag: string,
    code: string,
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
    public canBack = false;
    public showTranslateMenu: boolean;
    public showFlagImg: boolean;
    public languages: Lang[];
    public selectedLanguage: Lang;
    public brand = brand;
    public isConnectPage = false;
    public isAvatarPage = false;
    public isMyLessonPage = false;
    public isDictionaryPage = false;
    public coins: number;
    public level: number;

    private settings: { [key: string]: any };
    private defaultEducationalLevelId = defaultValue['grade'] ? defaultValue['grade'] : '';
    private defaultEducationalLevel: EducationalLevelEntity;
    private defaultConceptId = defaultValue['concept'] ? defaultValue['concept'] : '';
    private defaultConcept: ConceptEntity;
    private selectedEducationalLevel: EducationalLevelEntity;
    private selectedConcept: ConceptEntity;
    private $destroy = new Subject<void>();

    private profileService = inject(ProfileService);
    private headerNavService = inject(HeaderNavigationService);
    private communicationCenter = inject(CommunicationCenterService);
    private _translateService = inject(TranslateService);
    private router = inject(Router);
    private dialog = inject(MatDialog);
    private conceptsService = inject(ConceptsService);
    private educationalLevelService = inject(EducationalLevelService);

    public get educationalLevelLabel() {
        return this.suggestedEducationalLevel?.get('label');
    }

    public get suggestedConceptLabel() {
        return this.suggestedConcept?.get('label');
    }

    public get suggestedConceptImage() {
        return this.suggestedConcept?.get('image')?.uri;
    }

    public get suggestedEducationalLevel() {
        return this.selectedEducationalLevel ? this.selectedEducationalLevel : this.defaultEducationalLevel;
    }

    public get suggestedConcept() {
        return this.selectedConcept ? this.selectedConcept : this.defaultConcept;
    }

    public back(): void {
        if (!this.isConnectPage) {
            this.headerNavService.back();
        } else {
            this.router.navigate(['/profile/show-profile']);
        }

    }

    public toggleMenu(): void {
        this.communicationCenter.getRoom('mainMenu').next('toggle', null);
    }

    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

    ngOnInit(): void {
        this.settings = settingsToolbar.filterModel(modulesSettings.featureAccess);
        this.languages = <any>langs;
        // check if it is the connect page, to only display back button
        this.setIsConnectPage();
        this.setIsAvatarPage();
        this.setIsMyLessonPage();
        this.setIsDictionaryPage();
        this.router.events.subscribe((event) => {
            this.setIsConnectPage();
            if (event instanceof NavigationEnd) {
                this.setIsAvatarPage();
                this.setIsMyLessonPage();
                this.setIsDictionaryPage();
            }
        });

        combineLatest([
            this.conceptsService.getConcepts(),
            this.educationalLevelService.getEducationalLevels(),
            this.communicationCenter.getRoom('authentication').getSubject<UserDataEntity>('userData')
        ]).pipe(
            takeUntil(this.$destroy),
            tap(([concepts, educationalLevels, userData]) => {
                this.defaultConcept = concepts.entities.find(concept => concept.id === this.defaultConceptId);
                this.defaultEducationalLevel = educationalLevels.entities.find(level => level.id === this.defaultEducationalLevelId);

                this.selectedEducationalLevel = null;
                this.selectedConcept = null;

                if (userData?.get('config')) {
                    this.selectedConcept = concepts.entities.find(concept => concept.id === userData?.get('config')?.concept);
                    this.selectedEducationalLevel = educationalLevels.entities.find(level => level.id === userData?.get('config')?.educational_level);
                }
            }),
            tap(() => {
                if (!this.selectedEducationalLevel && !this.selectedConcept) {
                    this.openSelectEducationalLevelAndConceptModal();
                } else {
                    this.communicationCenter.getRoom('user').next('data', {
                        educational_level: this.selectedEducationalLevel ? this.selectedEducationalLevel.id : this.defaultEducationalLevelId,
                        concept: this.selectedConcept ? this.selectedConcept.id : this.defaultConceptId,
                    });
                }
            })
        ).subscribe();

        this.showTranslateMenu = this.settings.translationAccess.show;
        this.showFlagImg = this.settings.translationAccess.withFlag;
        this.headerNavService.backAvailabilityChanged.subscribe((value) => {
            this.canBack = value;
        });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});

        this.communicationCenter
            .getRoom('gamification')
            .getSubject('levelData')
            .pipe(takeUntil(this.$destroy))
            .subscribe((data) => {
                this.coins = data.points;
                this.level = data.level;
            });
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;
        localStorage.setItem('lang', lang.id);

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    setIsConnectPage(): void {
        this.isConnectPage = this.router.url === '/connect';
    }

    setIsAvatarPage(): void {
        this.isAvatarPage = this.router.url.includes('/my-avatar');
    }

    setIsMyLessonPage(): void {
        this.isMyLessonPage = this.router.url.includes('/my-assignments');
    }

    setIsDictionaryPage(): void {
        this.isDictionaryPage = this.router.url.includes('/dictionary');
    }


    openSelectEducationalLevelAndConceptModal(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            concept: this.selectedConcept ? this.selectedConcept.id : this.defaultConceptId,
            educational_level: this.selectedEducationalLevel ? this.selectedEducationalLevel.id : this.defaultEducationalLevelId,
        };
        dialogConfig.backdropClass = 'modalbox';
        dialogConfig.maxWidth = '400px';
        const selectionDialogRef = this.dialog.open(FuseSelectionDialogComponent, dialogConfig);

        selectionDialogRef.afterClosed()
            .subscribe(
                ({educational_level, concept}: {
                    educational_level: EducationalLevelEntity,
                    concept: ConceptEntity
                }) => {
                    if (educational_level && concept) {

                        // save datas to backoffice
                        const user = {
                            config: {
                                educational_level: educational_level.id,
                                concept: concept.id,
                            }
                        };
                        this.profileService.editUserProfile(user);

                        this.selectedEducationalLevel = educational_level;
                        this.selectedConcept = concept;
                        this.communicationCenter.getRoom('user').next('data', {
                            educational_level: educational_level.id,
                            concept: concept.id,
                        });
                        this.router.navigate(['lessons', 'grade', educational_level.id, 'concept', concept.id]);
                    }
                });
    }

    public help(target: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.backdropClass = 'modalbox';
        dialogConfig.maxWidth = '400px';
        if (target === 'lesson') {
            this.dialog.open(HelpLessonsDialogComponent, dialogConfig);
        }
        if (target === 'dictionary') {
            this.dialog.open(HelpDictionaryDialogComponent, dialogConfig);
        }
    }
}
