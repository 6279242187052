import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder} from '@angular/forms';
import {Observable} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-feedback-dialog-by-steps',
    templateUrl: './feedback-dialog-by-steps.component.html',
})
export class FeedbackDialogByStepsComponent implements OnInit {

    public useStep = false;
    public currentStep = 0;
    public useSmiley = null;
    public currentConcept = null;

    public form = this.fb.group({
        alone: [null],
        grade: [''],
        recommendation: [''],
        before: [''],
        after: [''],
        feedback: [''],
        genre: '',
        ranking: [''],
        // part for not alone answers
        numberOfPeople: [1],

        numberOfBoys: [0],
        numberOfGirls: [0],
        numberOfGenreNoAnswers: [0],

        gradeBoys: [''],
        gradeGirls: [''],
        gradeNoAnswers: [''],

        beforeBoys: [''],
        beforeGirls: [''],
        beforeNoAnswers: [''],

        afterBoys: [''],
        afterGirls: [''],
        afterNoAnswers: [''],

        recommendationBoys: [''],
        recommendationGirls: [''],
        recommendationNoAnswers: [''],

    });


    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        public selfDialogRef: MatDialogRef<FeedbackDialogByStepsComponent>,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: {
            save: (data) => Observable<DataEntity>,
            idLesson?: string,
            activity?: string
        }
    ) {
        this.communicationCenter.getRoom('root-filter').getSubject('selected').pipe(take(1)).subscribe(res => {
            this.currentConcept = res;
            if (res !== 0) {
                this.useSmiley = false;
            } else {
                this.useSmiley = true;
            }
        });
    }

    ngOnInit(): void {
        this.form.get('alone')?.valueChanges.subscribe(value => {
            if (value === 'false') {
                this.useStep = true;
                this.currentStep = 1;
            }
        });
    }

    onSubmit(): void {
        const myFeedback = {
            alone: this.form.value.alone,
            // alone part
            grade: this.form.value.grade,
            recommendation: this.form.value.recommendation,
            before: this.form.value.before,
            after: this.form.value.after,
            feedback: this.form.value.feedback,
            genre: this.form.value.genre,
            ranking: this.form.value.ranking,
            //not alone part
            numberOfPeople: this.form.value.numberOfPeople,
            numberOfBoys: this.form.value.numberOfBoys,
            numberOfGirls: this.form.value.numberOfGirls,
            numberOfGenreNoAnswers: this.form.value.numberOfGenreNoAnswers,
            gradeBoys: this.form.value.gradeBoys,
            gradeGirls: this.form.value.gradeGirls,
            gradeNoAnswers: this.form.value.gradeNoAnswers,

            beforeBoys: this.form.value.beforeBoys,
            beforeGirls: this.form.value.beforeGirls,
            beforeNoAnswers: this.form.value.beforeNoAnswers,

            afterBoys: this.form.value.afterBoys,
            afterGirls: this.form.value.afterGirls,
            afterNoAnswers: this.form.value.afterNoAnswers,

            recommendationBoys: this.form.value.recommendationBoys,
            recommendationGirls: this.form.value.recommendationGirls,
            recommendationNoAnswers: this.form.value.recommendationNoAnswers,
        };

        if (this.data?.idLesson && this.data?.activity) {
            myFeedback['activity'] = this.data?.activity;
        }

        this.data.save(myFeedback).subscribe((data) => {
            this.selfDialogRef.close();
        }, error => {
            console.log('error = ', error);
        });

    }

    accept(): void {
        this.selfDialogRef.close(true);
    }

    cancel(): void {
        this.selfDialogRef.close(false);
    }

    next(): void {
        this.currentStep = this.currentStep + 1;
    }

    getListOfValuesForRepartition(): number[] {
        return Array.from({length: this.form.get('numberOfPeople')?.value}, (_, i) => i + 1);
    }

    public answerWasMade(): boolean {
        return this.form.value.alone !== null && this.form.value.ranking && this.form.value.recommendation && this.form.value.before
            && this.form.value.after && this.form.value.genre;
    }
}