import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-demo-end-modal',
    templateUrl: './demo-end-modal.component.html',
    styleUrls: ['./demo-end-modal.component.scss']
})
export class DemoEndModalComponent {
    constructor(public dialogRef: MatDialogRef<DemoEndModalComponent>,) {
    }

}