import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DataEntity} from 'octopus-connect';
import {AuthenticationService} from '../authentication.service';
import {brand, brandLogoSvg, defaultLoginRoute, defaultURL} from '../../../../settings';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {currentTimestamp} from 'shared/utils/datetime';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
    selector: 'fuse-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FuseLoginComponent implements OnInit, OnDestroy {
    loginForm: UntypedFormGroup;
    loginFormErrors: any;
    hide = true;
    hideNewPassword = true;
    hideConfirmPassword = true;
    public brand = brand;
    public brandLogoSvg = brandLogoSvg;
    public renewPassword = false;
    message: any;
    private unsubscribeInTakeUntil = new Subject<void>();
    public modeSSO = false;
    public enableCode = false;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    settings: { [key: string]: any };
    public emailNotValidatedError = false;
    public newLinkSendSuccess = false;
    public newLinkSendError = false;
    public dialogRoleNotAllowedTitle: string;
    public dialogRoleNotAllowedBody: string;
    public quickConnectUsers = [];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private dialog: MatDialog,
        private communicationCenter: CommunicationCenterService
    ) {
        this.loginFormErrors = {
            login: {},
            password: {},
            newPassword: {},
            confirmPassword: {},
            emailValidation: {}
        };

        this.communicationCenter
            .getRoom('skeleton')
            .next('addClass', 'is-login-or-register-active');

        this.settings = this.authenticationService.settings;
        this.quickConnectUsers = this.authenticationService.getQuickConnectUsers();
    }

    ngOnInit(): void {
        this.initForm();
        this.enableCode = this.settings.enableCode;
    }

    initForm(): void {
        if (this.route.snapshot.queryParams.code) {
            this.modeSSO = true;
            this.authenticationService.loginSSO(this.route.snapshot.queryParams.code);
        }

        this.loginForm = this.formBuilder.group({
            login: ['', Validators.required],
            password: ['', Validators.required],
            newPassword: ['', Validators.required],
            confirmPassword: ['', Validators.required],
        }, {
            validator: this.mustMatchAndBeNewPassword('password', 'newPassword', 'confirmPassword')
        });


        this.loginForm.valueChanges.subscribe(() => {
            this.onLoginFormValuesChanged();
        });

        this.authenticationService.errorHttpAuthentication.pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(errorHttp => {
                if (errorHttp.code === 401) {
                    const controlLogin = this.loginForm.get('login');
                    const controlpassword = this.loginForm.get('password');
                    controlLogin.setErrors({invalid: true});
                    controlpassword.setErrors({invalid: true});
                    this.loginFormErrors['login'] = controlLogin.errors;
                    this.loginFormErrors['password'] = controlpassword.errors;
                } else if (errorHttp.code === 403) {
                    // email was not validated by email link
                    const controlLogin = this.loginForm.get('login');
                    controlLogin.setErrors({invalid: true});
                    this.loginFormErrors['emailValidation'] = controlLogin.errors;
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
        this.communicationCenter
            .getRoom('skeleton')
            .next('removeClass', 'is-login-or-register-active');
    }

    /**
     * custom validator to check that two fields match and are différent of original password
     *
     */
    private mustMatchAndBeNewPassword(passwordControlName: string, newPasswordcontrolName: string, confirmPasswordControlName: string): any {
        return (formGroup: UntypedFormGroup) => {
            const controlOriginalPasword = formGroup.controls[passwordControlName];
            const controlNewPassword = formGroup.controls[newPasswordcontrolName];
            const controlConfirmPassword = formGroup.controls[confirmPasswordControlName];

            if (controlConfirmPassword.errors && !controlConfirmPassword.errors.notMatch) {
                return;
            }

            if (controlOriginalPasword.value === controlNewPassword.value) {
                controlNewPassword.setErrors({samePasswordAsOrigine: true});
            } else {
                controlNewPassword.setErrors(null);
            }

            if (controlNewPassword.value !== controlConfirmPassword.value) {
                controlConfirmPassword.setErrors({notMatch: true});
            } else {
                controlConfirmPassword.setErrors(null);
            }
        };
    }

    /**
     * login user : with optionnal change password possibility force by rules
     */
    public login(): void {
        this.resetEmailSendMessages();
        if ((this.loginForm.value['login'] || this.enableCode) && this.loginForm.value['password']) {
            let loginValue = this.loginForm.value['login'];
            let passwordValue = this.loginForm.value['password'];
            if (this.enableCode) {
                // remove - from the registration code
                passwordValue = this.loginForm.value['password'].replace(/[-]/g, '');
                loginValue = passwordValue + '@nopasswordregistration.tralalere.com';
            }
            this.authenticationService.authenticateIn('http', loginValue, passwordValue).pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((user: UserDataEntity) => {
                    let isAllowed = false;
                    // check if the user's role is allowed to login
                    this.settings.rolesAllowedToLogin.forEach(function (role: number): void {
                        if (user.get('role').includes(role)) {
                            isAllowed = true;
                        }
                    });

                    if (isAllowed) {
                        if (this.settings.validateEmailStrategyActivated && !user.get('email_status')) {
                            this.authenticationService.logoutFrom('http');
                            return;
                        }

                        // set value is need for pass form on valid state
                        this.loginForm.controls.login.setValue(this.loginForm.value['login']);

                        if (this.isNewPasswordToSet()) {
                            this.updatePassword();
                            return;
                        }
                        // must be after condition this.isNewPasswordToSet()
                        if (this.isExpirePassword(user)) {
                            this.renewPassword = true;
                            this.authenticationService.logoutFrom('http');
                            return;
                        }

                        const isTrainer = !!(user.get('role').findIndex(role => role === 5) >= 0); // tester autrement qu'avec un numero (voir authService)
                        // it's fisrts user trainer connexion and help is active
                        if (user.get('access') && user.get('first_access') === true && this.settings.askForHelp && isTrainer) {
                            this.help();
                        } else {
                            this.navigate();
                        }

                        if (this.settings.quickConnect){
                            this.authenticationService.addQuickConnectUser(user);
                        }

                    } else {
                        this.translate.get('generic.role_not_allowed_title').subscribe((translation: string) => this.dialogRoleNotAllowedTitle = translation);
                        this.translate.get('generic.role_not_allowed_body').subscribe((translation: string) => this.dialogRoleNotAllowedBody = translation);
                        const dialogConfig = new MatDialogConfig();
                        dialogConfig.data = {
                            titleDialog: this.dialogRoleNotAllowedTitle,
                            bodyDialog: this.dialogRoleNotAllowedBody
                        };

                        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);

                        dialogRef.afterClosed().subscribe(result => {
                            this.router.navigate(['logout']);
                        });
                    }
                });
        }
    }

    /**
     * reset messages success and error
     * @private
     */
    private resetEmailSendMessages(): void {
        this.newLinkSendSuccess = false;
        this.newLinkSendError = false;
    }

    /**
     * open modal asking if user need help at first connexion
     */
    private help(): void {
        const data = {
            titleDialog: 'generic.ask.help',
            bodyDialog: 'generic.ask.help.content',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        };

        this.translate.get(data.titleDialog).subscribe((translation: string) => data.titleDialog = translation);
        this.translate.get(data.bodyDialog).subscribe((translation: string) => data.bodyDialog = translation);
        this.translate.get(data.labelTrueDialog).subscribe((translation: string) => data.labelTrueDialog = translation);
        this.translate.get(data.labelFalseDialog).subscribe((translation: string) => data.labelFalseDialog = translation);

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data: data
        });

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                // help page
                this.router.navigate(['home']);
            } else {
                // normal navigate
                this.navigate();
            }
            this.confirmDialogRef = null;
        });
    }

    /**
     * update password and go to program if no error
     */
    private updatePassword(): void {
        this.authenticationService.loggedUser.set('password', this.loginForm.value['newPassword']);
        this.authenticationService.loggedUser.save().subscribe((userUpdate: DataEntity) => {
            this.renewPassword = false;
            this.navigate();
        }, error => {
            console.log(error);
        });
    }

    /**
     * is user expiration password date is passed
     * @param user: DataEntity
     */
    private isExpirePassword(user: DataEntity): boolean {
        return this.settings.activeChangePasswordStrategy && user.get('expirePassword')
            && currentTimestamp() >= user.get('expirePassword')
            && user.get('expirePassword') !== null && user.get('expirePassword') !== undefined;
    }

    /**
     * is the new password need to be set
     */
    private isNewPasswordToSet(): boolean {
        return this.settings.activeChangePasswordStrategy &&
            this.loginForm.value['newPassword'] && this.loginForm.value['newPassword'] !== null
            && this.loginForm.value['newPassword'] !== undefined && this.loginForm.value['newPassword'] !== '';
    }

    /**
     * navigate on the good route after being logged
     */
    private navigate(): void {
        let url = defaultLoginRoute;
        const returnParam: string = this.route.snapshot.queryParams['return'];

        // Si la route est définie par l'actuelle url, c'est la route prioritaire
        if (returnParam) {
            url = returnParam;
        } else if (this.authenticationService.isFirstConnexion()) {
            // Sinon si c'est la premiere connexion, on vérifie qu'on a pas une route particulière de bienvenue
            const userRole = this.authenticationService.accessLevel;
            const redirectRules = this.settings.firstConnexionRedirection;
            const forceRedirection = redirectRules.hasOwnProperty(userRole) ? redirectRules[userRole] : redirectRules.default;
            if (forceRedirection !== undefined) {
                url = forceRedirection;
            }
        }

        this.router.navigateByUrl(url);
    }

    loginSSO(): void {
        if (this.settings.enableSSO) {
            window.location.href = this.settings.urlSSO[this.translate.currentLang] + defaultURL + 'login';
        }
    }

    onLoginFormValuesChanged(): void {
        this.resetEmailSendMessages();

        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);
            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }

    /**
     * user haven't valdiate is email send a new link to register email
     */
    sendLinkToValidateEmail(): void {
        this.authenticationService.sendNewLinkEmailValidation(this.loginForm.value['login']).subscribe(email => {
            this.newLinkSendSuccess = true;
            const controlLogin = this.loginForm.get('login');
            controlLogin.setErrors({});
            this.loginFormErrors['emailValidation'] = controlLogin.errors;
            setTimeout(() => {
                this.newLinkSendSuccess = false;
            }, 3000);
        }, (error: Object) => {
            this.newLinkSendError = true;
            console.error(error);
        });

    }

    get selfSignup(): boolean {
        return this.settings.selfSignup;
    }

    get signup(): boolean {
        return this.settings.signup;
    }

    public switchLoginForm(): void {
        this.enableCode = !this.enableCode;
    }

    public addHyphen(val): void {
        let chIbn = val.split('-').join('');
        if (chIbn.length > 0) {
            chIbn = chIbn.match(new RegExp('.{1,4}', 'g')).join('-');
        }
        console.log(chIbn);
    }
}