import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity, OctopusConnectService} from 'octopus-connect';

@Component({
    selector: 'app-add-text-modal',
    templateUrl: './add-text-modal.component.html',
    styleUrls: ['./add-text-modal.component.scss']
})
export class AddTextModalComponent implements OnInit {
    public text: string;
    constructor(
        private communicationCenter: CommunicationCenterService,
        @Optional() @Inject(MAT_DIALOG_DATA) private data: { resource: DataEntity },
        private octopusConnect: OctopusConnectService,
        public selfDialogRef: MatDialogRef<AddTextModalComponent>,
    ) {
    }

    ngOnInit(): void {
        this.communicationCenter
            .getRoom('corpus')
            .next('loadFormats', true);

        this.text = this.data.resource !== null ? this.data.resource['reference'].text : '';
    }

    onSave(): void {
        if (this.data.resource !== null) {
            const media = new DataEntity('corpus-text-ressource', this.data.resource['reference'], this.octopusConnect, this.data.resource['reference'].id);
            media.set('text', this.text);
            media.save().subscribe(() => {
                this.selfDialogRef.close();
            });
        } else {
            const data = {
                type: 'text',
                inCorpus: false,
                text: this.text,
                metadataInterface: {
                    title: 'Text-resource'
                },
                callback: (corpusResource) => {
                    this.selfDialogRef.close(corpusResource);
                }
            };

            this.communicationCenter
                .getRoom('corpus')
                .next('createTextResource', data);
        }

    }

    onCancel(): void {
        this.selfDialogRef.close();
    }
}