import {ActivatedRoute, Router} from '@angular/router';
import {GamificationService} from './../gamification.service';
import {Component, OnInit, OnDestroy, inject} from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {PreviewItemPopupComponent} from '../preview-item-popup/preview-item-popup.component';
import {firstBy} from 'thenby';
import {Observable, Subject} from 'rxjs';
import {filter, map, mergeMap, takeUntil, tap} from 'rxjs/operators';

@Component({
    selector: 'app-badge-type-gallery',
    templateUrl: './badge-type-gallery.component.html'
})
export class BadgeTypeGalleryComponent implements OnInit, OnDestroy {

    /** Properties */
    public badges: DataEntity[] = [];
    public badgeListName$ : Observable<string>;

    private unsubscribeTakeUntil: Subject<void> = new Subject<void>();

    /** Injected services */
    public dialog = inject(MatDialog);
    private dialogRef: MatDialogRef<PreviewItemPopupComponent>;
    private octopusConnect = inject(OctopusConnectService);
    private gamificationService = inject(GamificationService);
    private activatedRoute = inject(ActivatedRoute);
    private router = inject(Router);

    /** BUILT-IN METHODS */

    ngOnInit(): void {
        // force scrolling to
        this.scrollToTop();
        // Load assets based on the route parameters
        this.resetBadgesList();
        // Handle page reload
        this.gamificationService.skinsObservable.pipe(
            takeUntil(this.unsubscribeTakeUntil)
        ).subscribe(() => {
            this.resetBadgesList();
        });

        this.badgeListName$ = this.octopusConnect.loadEntity('badges-type', this.activatedRoute.snapshot.params['typeId']).pipe(
            map((data: DataEntity) => {
                if (data) {
                    return data.get('name');
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.unsubscribeTakeUntil.next();
        this.unsubscribeTakeUntil.complete();

    }

    /** LOGIC */

    private resetBadgesList(): void {
        this.badges = this.gamificationService.skins.filter((badge) => {
            return badge.attributes['type']['id'] === this.activatedRoute.snapshot.params['typeId'];
        }, this).sort(
            firstBy((a: DataEntity, b: DataEntity) => {
                return b.get('selected') - a.get('selected');
            })
                .thenBy((a: DataEntity, b: DataEntity) => {
                    return b.get('unLocked') - a.get('unLocked');
                }).thenBy((a: DataEntity, b: DataEntity) => {
                return a.get('level') - b.get('level');
            }, 1)
        );
    }

    public scrollToTop(): void {
        document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0, 0);
    }

    public goBacktoAvatarPage(): void {
        this.router.navigate(['/my-avatar']);
    }

    public invoqueBuyPopInOrEquip(badge: DataEntity): void {
        if ((!badge.attributes['selected'] && badge.attributes['buyable']) || badge.attributes['selected']) {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.panelClass = 'preview_badge_modal';
            dialogConfig.backdropClass = 'backdrop-blur';
            dialogConfig.data = badge;
            this.dialogRef = this.dialog.open(PreviewItemPopupComponent, dialogConfig);
            this.dialogRef.afterClosed().pipe(takeUntil(this.unsubscribeTakeUntil))
                .pipe(
                    filter((data: {isSelected: boolean, badge: DataEntity}) => data.isSelected !== data.badge.get('selected')),
                    mergeMap((data: {isSelected: boolean, badge: DataEntity}) => this.gamificationService.doActionDependingOnType(data)),
                    tap(() => this.resetBadgesList())
                )
                .subscribe();
        }
    }
}