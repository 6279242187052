import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChaptersSelectionComponent} from 'fuse-core/components/chapters-selection/chapters-selection.component';
import {ChaptersService} from 'fuse-core/components/chapters-selection/chapters.service';
import {TranslateModule} from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import {FuseSharedModule} from 'fuse-core/shared.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatToolbarModule,
    FuseSharedModule,
    MatSelectModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCheckboxModule,
    MatProgressSpinnerModule

  ],
  declarations: [
      ChaptersSelectionComponent
  ],
    providers: [
        ChaptersService
    ],
})
export class ChaptersModule { }