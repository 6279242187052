import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {FuseSharedModule} from '@fuse/shared.module';

import {GamificationService} from './gamification.service';
import {GamificationComponent} from './gamification/gamification.component';
import {SharedModule} from '../../../shared/shared.module';
import {GalleryThumbnailComponent} from './gallery-thumbnail/gallery-thumbnail.component';
import {AccessoryThumbnailComponent} from './accessory-thumbnail/accessory-thumbnail.component';
import {GalleryComponent} from './gallery/gallery.component';
import {MyAnimalsComponent} from './my-animals/my-animals.component';
import {AnimalCanvasComponent} from './animal-canvas/animal-canvas.component';
import {AnimalControlsComponent} from './animal-controls/animal-controls.component';
import {AccessoriesPopupComponent} from './accessories-popup/accessories-popup.component';
import {BuyPopupComponent} from './buy-popup/buy-popup.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AutofocusDirective} from './autofocus.directive';
import {DataEntity} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {MyAvatarComponent} from './my-avatar/my-avatar.component';
import {BadgeTypeGalleryComponent} from './badge-type-gallery/badge-type-gallery.component';
import {BadgeTypeListComponent} from './badge-type-list/badge-type-list.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import { AnimatedCanvasComponent } from './animated-canvas/animated-canvas.component';
import { PreviewItemPopupComponent } from './preview-item-popup/preview-item-popup.component';
import {AnimatedCanvasInMyProgressComponent} from './animated-canvas/animated-canvas-in-my-progress/animated-canvas-in-my-progress.component';
import { AnimatedCanvasInRewardPageComponent } from './animated-canvas/animated-canvas-in-reward-page/animated-canvas-in-reward-page.component';

const routes: Routes = [
    {
        path: 'avatars',
        canActivate: [IsUserLogged],
        component: GamificationComponent
    },
    {
        path: 'my-avatar',
        canActivate: [IsUserLogged],
        component: MyAvatarComponent,
        children: [
            {
                path: '',
                component: BadgeTypeListComponent
            },
            {
                path: 'badge-gallery/:typeId',
                component: BadgeTypeGalleryComponent
            }
        ]

    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        MatProgressSpinnerModule,
        RouterModule.forChild(routes),
        FlexLayoutModule,
    ],
    providers: [],
    declarations: [
        GamificationComponent,
        GalleryThumbnailComponent,
        AccessoryThumbnailComponent,
        GalleryComponent,
        MyAnimalsComponent,
        AnimalCanvasComponent,
        AnimalControlsComponent,
        AccessoriesPopupComponent,
        BuyPopupComponent,
        AutofocusDirective,
        MyAvatarComponent,
        BadgeTypeGalleryComponent,
        BadgeTypeListComponent,
        AnimatedCanvasComponent,
        PreviewItemPopupComponent,
        AnimatedCanvasInMyProgressComponent,
        AnimatedCanvasInRewardPageComponent,
    ]
})
export class GamificationModule {
    private static isMenuSet = false;

    constructor(
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private gamificationService: GamificationService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
        // component needeed to show animation use com center to avoid module dependencies
        this.communicationCenter
            .getRoom('gamification')
            .getSubject('animatedCanvaForInMyProgressComponent')
            .next(AnimatedCanvasInMyProgressComponent);

        // component needeed to show animation in reward page
        this.communicationCenter
            .getRoom('gamification')
            .getSubject('AnimatedCanvasInRewardPage')
            .next(AnimatedCanvasInRewardPageComponent);

    }

    static forRoot(): ModuleWithProviders<GamificationModule> {

        return {
            ngModule: GamificationModule,
            providers: [
                GamificationService
            ]
        };
    }

    private postLogout(): void {
        GamificationModule.isMenuSet = false;
        //this.dynamicNavigation.clearMenuItem('level0', 'gamification');
    }

    private postAuthentication(): void {
        this.gamificationService.loadAvatar();
        // if (!GamificationModule.isMenuSet && this.authService.hasLevel(['learner'])) {
        //     this.dynamicNavigation.registerModuleMenu('level0', {
        //         'id': 'gamification',
        //         'title': 'Ma collection',
        //         'translate': 'navigation.gamification',
        //         'type': 'item',
        //         'icon': 'avatar',
        //         'url': '/avatars',
        //     });

        //     GamificationModule.isMenuSet = true;
        // }
    }

}