import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-closing-pop-up',
    templateUrl: './closing-pop-up.component.html',
})
export class ClosingPopUpComponent {

    constructor(public dialogRef: MatDialogRef<ClosingPopUpComponent>,
                @Inject(MAT_DIALOG_DATA) data) {
    }
}