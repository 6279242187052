import {Component, Inject, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class FuseConfirmDialogComponent implements AfterViewInit {
    public titleDialog: string;
    public bodyDialog: any;
    public labelConfirmDialog: string;
    public labelConfirmDialogPlaceHolder: string;
    public labelTrueDialog: string;
    public labelFalseDialog: string;
    public labelOtherDialog: string; // other button for return info to parent that we have click on it
    public confirmMessage: string;
    public showReportbutton: boolean;
    public enableTTS: boolean = false;
    public resourceId: string;
    public inputValue: string;
    public trueSvgDialog: string;
    public falseSvgDialog: string;
    public isTTSSpeaking: boolean = false;
    public hideCloseBtn: boolean = false;
    private isAutoFocus?: boolean = false; // is pass to true if in data autofocus field exist
    @ViewChild('inputToFocus') inputToFocus: ElementRef;


    /**
     * Constructor
     *
     * @param domSanitizer
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     * @param data
     * @param ref
     */
    constructor(
        private domSanitizer: DomSanitizer,
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private ref: ChangeDetectorRef) {


        this.titleDialog = data.titleDialog;
        this.enableTTS = data.enableTTS;
        this.bodyDialog = this.domSanitizer.bypassSecurityTrustHtml(data.bodyDialog);
        this.labelTrueDialog = data.labelTrueDialog;
        this.labelFalseDialog = data.labelFalseDialog;
        this.labelConfirmDialog = data.labelConfirmDialog;
        this.labelOtherDialog = data.labelOtherDialog;
        this.labelConfirmDialogPlaceHolder = data.labelConfirmDialogPlaceHolder;
        this.showReportbutton = data.showReportbutton;
        this.hideCloseBtn = data.hideCloseBtn;
        this.trueSvgDialog = data.trueSvgDialog;
        this.falseSvgDialog = data.falseSvgDialog;
        this.resourceId = data.resourceId;
        if (data.autofocus) {
            this.isAutoFocus = true;
        }
    }

    speakStateChanged(data: {id: string, value: boolean }): void {
        this.isTTSSpeaking = data.value;
        this.ref.detectChanges();
    }

    /**
     * autofocus on input field if data contain field autofocus by default value is false
     */
    ngAfterViewInit(): void {
        if (this.isAutoFocus) {
            setTimeout(() => this.inputToFocus.nativeElement.focus(), 1000);
        }
    }
}