import {filter, take, takeUntil, tap} from 'rxjs/operators';
import {ChangeDetectorRef, Component, HostListener, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {UserActionsService} from '@modules/activities/core/services/user-actions.service';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {FullscreenService} from '@fuse/services/fullscreen.service';
import {AuthenticationService} from '@modules/authentication';
import {defaultLoginRoute, userReview} from '../../../../settings';
import {StepperOptions} from '@modules/activities/core/shared-components/generic-stepper/generic-stepper.component';
import {GenericPluginsService, PluginType} from '@modules/activities/core/services/generic-plugins.service';
import {PluginSetting} from '../models/plugin.setting';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {CommunicationCenterService} from '@modules/communication-center';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {AnswerResultInterface, LessonGranuleEntity} from '@modules/activities/core/models';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {StepperService} from '@modules/activities/core/services/stepper.service';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';
import {ActivityState} from '@modules/activities/core/lessons/activityState';
import * as _ from 'lodash-es';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {UserReviewComponent} from 'fuse-core/components/user-review/user-review.component';
import {Platform} from '@angular/cdk/platform';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {UserReviewService} from 'fuse-core/components/user-review/user-review.service';
import {FormDialogService} from 'fuse-core/components/form-dialog/form-dialog.service';

@Component({
    selector: 'fuse-app-lessons',
    templateUrl: './lessons.component.html',
})
export class LessonsComponent implements OnInit, OnDestroy {
    private userReviewService = inject(UserReviewService);
    public lessonTitle: string;
    public activityTitle = '';
    public stepTitle: string;
    // état des activités défini par des nombres
    public userAnswered: ActivityState[] = [];
    public isLoading = false;
    public learnerInfo: { classes: string, groups: string, name: string, uid: string };
    public isLatexKeyboardDisplayed: boolean;
    public onloadActivityAsCurrent = new Subject<DataEntity>();
    public stepperOptions: StepperOptions;
    public numberOfQuestion = 0;
    public answersCopy: AnswerResultInterface[] = [];
    // use when reward page is opened, must be true by default
    public showHeaderExo = true;
    public allowedToolsPlugins: { toolIdentifier: string; setting: PluginSetting }[] = [];
    private barIsHidden = false;
    private previousConceptIdList = '';
    private unsubscribeInTakeUntil = new Subject<void>();
    // index list of visited activities
    private visitedActivity: number[] = [];
    private unSeenFirst: number;
    // contain all data from multiple lesson present in assignment with mulple lessons
    private currentActivity: DataEntity;
    private currentActivityPlayedIndex: number = null;
    private userSavesOfCurrentLesson: DataEntity[] = [];
    public headerButtonAreLocked = false;
    public isDisplayStepper = true;
    public isMobile: boolean;
    private userReviewSetting: string[] = userReview;
    private _bottomSheet = inject(MatBottomSheet);
    public dialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    public dialogNpsRef: MatDialogRef<UserReviewComponent>;
    public _bottomSheetRef: MatBottomSheetRef<UserReviewComponent>;


    constructor(
        private router: Router,
        public activitiesService: ActivitiesService,
        private userActionsService: UserActionsService,
        private activatedRoute: ActivatedRoute,
        private genericPluginsService: GenericPluginsService,
        public lessonsService: LessonsService,
        private authService: AuthenticationService,
        public fullscreenService: FullscreenService,
        public stepperService: StepperService,
        public dialog: MatDialog,
        private communicationCenter: CommunicationCenterService,
        private translate: TranslateService,
        private lessonsConfigurationService: LessonsConfigurationService,
        private ref: ChangeDetectorRef,
        private _platform: Platform,
        private formDialogService: FormDialogService
    ) {
        this.isMobile = false;
        this.listenProgressBarExoEvent();
        this.communicationCenter
            .getRoom('app')
            .getSubject('visibility')
            .next(true);

        this.communicationCenter.getRoom('header-exo').getSubject('show')
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(res => {
                this.showHeaderExo = res;
            });

        // when user make an answer if it's wrong and if this answer has a feedback this must open a modal with feedback
        this.communicationCenter.getRoom('header-exo').getSubject('show-custom-feedback')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((res: { feedback: string, title: string, callback?: () => Observable<DataEntity> }) => {
                this.help(res.feedback, res.title, res.callback);
            });

        this.communicationCenter.getRoom('activities')
            .getSubject('backToLesson')
            .pipe(takeUntil(this.unsubscribeInTakeUntil), filter((val) => val))
            .subscribe(({forceUrl, initialiseSubject, params}: { forceUrl: string[], initialiseSubject: boolean, params?: Params }) => {
                if (initialiseSubject) {
                    if (forceUrl && forceUrl.length && !router.url.includes('contests')) {
                        // lorsque l'on souhaite forcer une url on la recuperer depuis les datas envoyées via communicationCenter
                        // pas de forcage pour le contest
                        this.exitLesson(forceUrl, params);
                    } else {
                        this.exitLesson(null, params);
                    }
                }
            });

        this.communicationCenter.getRoom('activities').getSubject('retryCurrentActivity')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil),
                filter((params: Params) => !!params)
            )
            .subscribe((params: Params) => {
                this.activitiesService.playScreenStatus = 1;
                this.navigateToActivity(this.lessonsService.currentLesson.get('reference').findIndex((activity) => +activity.id === +this.currentActivity.id), params, true);
            });

        this.communicationCenter.getRoom('activities').getSubject('nextActivity')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil),
                filter((queryParams: Params) => !!queryParams),
                tap(() => this.activitiesService.playScreenStatus = 1)
            )
            .subscribe((params: Params) => {
                const indexCurrentActivity = this.lessonsService.currentLesson.get('reference').findIndex((activity) => +activity.id === +this.currentActivity.id);
                if (indexCurrentActivity < this.lessonsService.currentLesson.get('reference').length - 1) {
                    this.currentActivity = this.lessonsService.activities[indexCurrentActivity + 1];
                    const queryParams = _.merge({}, params, {startOnStepIndex: indexCurrentActivity + 1});
                    if (this.lessonsConfigurationService.getActivitiesBroadcastLifeCycle()) {
                        // pour les traces, si pas de questionset, on envoie l'id du parcours parent
                        let id = this.lessonsService.currentLesson.id;
                        if (this.currentActivity?.get('format')?.label === 'lesson') {
                            queryParams.subLessonId = this.currentActivity.id;
                            id = this.currentActivity.id;
                        }
                        this.communicationCenter
                            .getRoom('lrs')
                            .getSubject('activity_initialize')
                            .next({id: `questionSet/${id}`, assignmentId: this.lessonsService.currentAssignment?.id || null});
                    }
                    this.navigateToActivity(indexCurrentActivity + 1, queryParams);
                }
            });

        this.communicationCenter
            .getRoom('skeleton')
            .next('addClass', 'is-player-active');

        this.communicationCenter
            .getRoom('stepper')
            .getSubject('locked')
            .pipe(takeUntil(this.unsubscribeInTakeUntil),
                tap((isLockedStepper: boolean) => this.headerButtonAreLocked = isLockedStepper))
            .subscribe();

        this.communicationCenter
            .getRoom('stepper')
            .getSubject('display')
            .pipe(
                tap((isDisplayStepper: boolean) => this.isDisplayStepper = isDisplayStepper)
            ).subscribe();
    }

    public get showActivityTitle(): boolean {
        return this.lessonsConfigurationService.getShowActivityTitle() && this.activityTitle && !this.activityTitle === null && !this.stepperOptions.isListFormatStepper;
    }

    public get showActivityQuestionIndex(): boolean {
        return this.lessonsConfigurationService.getShowActivityQuestionIndex();
    }

    public get showLessonTitle(): boolean {
        return this.lessonsConfigurationService.getShowLessonTitle();
    }

    public get lessonTitleIcon(): boolean {
        return this.lessonsConfigurationService.getShowIconTitleLesson();
    }

    public get hasLessonSteps(): boolean {
        return this.lessonStep && this.lessonStep.typeSteps && this.lessonStep.typeSteps.length > 0;
    }

    public get hasOneLessonStep(): boolean {
        return this.lessonsService.currentLesson && this.lessonsService.currentLesson.get('reference') && this.lessonsService.currentLesson.get('reference').length === 1;
    }

    public get displayFullscreenButton(): boolean {
        return this.lessonsConfigurationService.getDisplayFullscreenButton();
    }

    /**
     * use png or svg in regard of settings
     */
    public get displayCloseButtonPng(): boolean {
        return this.lessonsConfigurationService.getCloseButtonPng();
    }

    /**
     * show help button or not
     */
    public get displayHelpButton(): boolean {
        return this.lessonsConfigurationService.getShowHelp();
    }

    /**
     * progression of the learner on the current assignement in number format
     */
    get progression(): number {
        return this.activitiesService.currentAssignment
        && this.activitiesService.currentAssignment.attributes.progress ? +this.activitiesService.currentAssignment.attributes.progress : 0;
    }

    /**
     * Display feedbacks section boolean
     */
    public get displayFeedbacks(): boolean {
        return this.lessonsConfigurationService.getDisplayFeedbacks() // setting authorize feedbacks
            && this.lessonsService.currentAssignment.get('hideFeedbacks') === false // Current assignment don't override feedback's authorization
            && this.activitiesService.currentActivityIsRecap() === false; // But don't show the feedback menu in the last "page" : the recap !
    }

    public get currentStep(): number {
        return this.currentActivityPlayedIndex;
    }

    private get lessonStep(): any {
        return this.lessonsConfigurationService.getLessonStep();
    }

    /**
     * all the constructor code is move here because we need to listen
     * load of same component with other id and launch all the code again in that case
     */
    initialiseState(): void {
        this.activitiesService.endScreenSeen = false;
        this.activitiesService.isUserAnswerStatus.pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((data) => {
                if (this.activitiesService.activitiesArray.length) {
                    if (data.status) {
                        this.userAnswered = this.activitiesService.activityAnswerResult;
                        this.refreshStepper();
                    }
                }
            });

        if (this.authService.isAtLeastTrainer() && this.activitiesService.currentAssignment && this.lessonsService.displayLearnerInfo()) {
            this.learnerInfo = this.activitiesService.currentAssignment.get('assignated_user');
        }

        this.allowedToolsPlugins = this.getToolsPlugins();

        this.refreshStepper();

        if (this.lessonsConfigurationService.shouldStartOnFullscreen()) {
            this.fullscreenService.toggleFullscreen();
        }
    }

    ngOnInit(): void {
        this.defaultFooterVisible(true);
        // use for multi assignment because component is not destroy before changing content
        this.activatedRoute.params.pipe(
            takeUntil(this.unsubscribeInTakeUntil)
        ).subscribe(() => {
            this.reset(false);
            this.initialiseState();
            this.initSubscribes();
            this.gotoEndPage();
        });

        this.listenLastConceptSelected();

    }

    /**
     * check if we can navigate in previous activity in the stepper
     * @param stepItem
     * @returns {boolean}
     */
    public canNavigateToStep(stepItem): boolean {
        if (this.activitiesService.settings['navigationInStepsAllowed']) {
            if (this.lessonsService.isAssignmentWithMetacognition() &&
                this.activitiesService.activitiesArray.indexOf(stepItem) < this.activitiesService.activitiesArray.indexOf(this.currentActivity)) {
                return false;
            }
            if (!this.activitiesService.settings['navigateInPreviousStep']) {
                return this.activitiesService.activitiesArray.indexOf(stepItem) >= this.activitiesService.activitiesArray.indexOf(this.currentActivity);
            }
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy(): void {
        this.formDialogService.isContest = false; // reset on exit
        this.communicationCenter.getRoom('header-exo').removeSubject('show');
        this.communicationCenter.getRoom('header-exo').removeSubject('show-custom-feedback');
        this.communicationCenter.getRoom('activities').getSubject('backToLesson').next(null);

        if (this.unsubscribeInTakeUntil) {
            this.unsubscribeInTakeUntil.next();
            this.unsubscribeInTakeUntil.complete();
        }
        this.reset(false);
        this.defaultFooterVisible(false);
        this.activitiesService.answersProgressBarMultiZone = [];
        this.communicationCenter
            .getRoom('skeleton')
            .next('removeClass', 'is-player-active');
    }

    @HostListener('window:beforeunload', ['$event'])
    checkSaved($event: any): void {
        if (this.lessonsService.savingAssignment) {
            $event.returnValue = true;
        }
    }

    public setActivityVisitInfo() {
        const actno = this.activitiesService.presentArrayElementIndex;
        const stepitem = this.activitiesService.activitiesArray[actno];
        const mediaTypes = ['divider', 'media', 'image', 'video', 'audio', 'document', 'url', 'videoUrl'];
        if (stepitem !== undefined && mediaTypes.includes(stepitem.type) && !this.activitiesService.visitedMediaActivity.includes(actno)) {
            this.activitiesService.visitedMediaActivity.push(actno);
        }
        if (this.activitiesService.playScreenStatus === 0) {
            this.visitedActivity.push(this.activitiesService.presentArrayElementIndex);
        }

        // find the first activity unseen in the current lesson
        if (this.activitiesService.activitiesArray.length) {
            const activities = this.activitiesService.activitiesArray;

            const unseenFirstTemp = activities.findIndex((activity) => {
                if (activities.length > this.activitiesService.presentArrayElementIndex + 1) {
                    return +activity.id === +activities[this.activitiesService.presentArrayElementIndex + 1].id;
                }
                return +activity.id === +activities[this.activitiesService.presentArrayElementIndex].id;
            });
            // if unseenfirst < keep greatest value for be able to go to next empty one
            // if we don t do that we will be blocked at step just after unseenFirst index who is lesser than last index of activities alrealy done
            if (this.unSeenFirst < unseenFirstTemp) {
                this.unSeenFirst = unseenFirstTemp;
            }
        }
        this.refreshStepper();
    }

    public checkLastVisited(): boolean {
        return this.visitedActivity.includes(this.activitiesService.activitiesArray.length - 1);
    }

    public gotoEndPage(): void {
        /*todo: in progress, if we reach the last activity in the current lesson for assignment with multiple lesson,
        need to know if we are in the last lesson to navigate to recap*/
        if (this.activitiesService.playScreenStatus === 1 || this.lessonsService.isAssignmentWithMetacognition()) {
            return;
        }

        if (this.activitiesService.endScreenSeen || this.checkLastVisited() || !this.authService.isLearner()) {
            let allSeen = this.activitiesService.activitiesArray.length;
            if (this.authService.isLearner()) {
                allSeen = this.visitedActivity.length;
            }
            if (this.activitiesService.activitiesArray.length <= allSeen + 1) {
                this.activitiesService.playScreenStatus = 3;

                this.activitiesService.endScreenSeen = true;
                const endPagePath = this.lessonsConfigurationService.useSummaryPageInsteadOfRecap() ? 'summary' : 'recap';
                this.router.navigate([endPagePath], {relativeTo: this.activatedRoute});

                this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
                this.lessonsService.lessonButtonClicked.next(true);
            }
        }
        this.isLoading = false;
    }

    // TODO utiliser le onloadActivityAsCurrent pour mutualiser les facon de naviguer
    public gotoCurrentPage(index, params: Params = {}): void {
        if (this.activitiesService.playScreenStatus === 1) {
            return;
        }

        if (
            !this.lessonsService.isLessonTest() &&
            !this.visitedActivity.includes(index) && (
                this.unSeenFirst !== index && !this.lessonsConfigurationService.isStepperAllowedAllSteps())
        ) {
            return;
        }
        const altPath = this.activitiesService.playScreenStatus === 3;
        this.activitiesService.playScreenStatus = 0;
        this.activitiesService.loadActivityByStep(index, altPath, params);
        this.lessonsService.lessonButtonClicked.next(true);
    }

    /**
     * process usersave state for the stepper and navigate to the activity or load recap if it is the last activity
     * @param activities
     * @param saves
     * @param loadOnStep
     * @param {boolean} alreadyLessonLoaded
     */
    processActivitiesAndUserSaves(activities: DataEntity[],
                                  saves: TypedDataEntityInterface<{ state: string, granule: (string | number)[] }>[],
                                  loadOnStep,
                                  alreadyLessonLoaded = true): void {
        let unseenStep = 0;
        this.lessonsService.activities = activities;
        if (saves && this.lessonsConfigurationService.getStepperLoadStatus()) {
            let index: number;
            let status: ActivityState;

            for (const save of saves) {
                index = activities.findIndex(activity => activity.id === save.get('granule')[0]);

                if (index > -1) {
                    switch (save.get('state')) {
                        case 'validated':
                            status = 1;
                            break;
                        case 'incomplete':
                            status = 2;
                            break;
                        case 'correct':
                            status = 3;
                            break;
                        default:
                            status = 2;
                            break;
                    }

                    if (!this.activitiesService.activityAnswerResult[index] || this.activitiesService.activityAnswerResult[index] === 2) {
                        this.activitiesService.activityAnswerResult[index] = status;
                    }

                    if (index >= unseenStep) {
                        unseenStep = index + 1;
                    }
                }
            }

            const activityIndex = unseenStep + 1;
            const stepItem = this.activitiesService.activitiesArray[unseenStep];
            // if media, url or divider is the last step go to recap
            if (activityIndex === this.activitiesService.activitiesArray.length
                && ['audio', 'video', 'image', 'document', 'media', 'divider', 'url', 'videoUrl']
                    .includes(stepItem.type)
            ) {
                unseenStep += 1;
            }

            this.userAnswered = this.activitiesService.activityAnswerResult;
            // eslint-disable-next-line @typescript-eslint/no-shadow
            for (let index = 0; index < this.activitiesService.activityAnswerResult.length; index += 1) {
                this.visitedActivity.push(index);

                if (
                    ['audio', 'video', 'image', 'document', 'media', 'divider', 'url', 'videoUrl']
                        .includes(this.activitiesService.activitiesArray[index].type)
                ) {
                    this.activitiesService.visitedMediaActivity.push(index);
                }
            }
        }

        // When going directly to summary, load errorsCount from saves into answersProgressBarMultiZone used in summary
        if (saves && this.activatedRoute.snapshot?.queryParams?.navigateDirectlyToSummary) {
            let errorsCount = 0;

            for (const save of saves) {
                errorsCount = +save.get('errorsCount');

                if (isNaN(errorsCount)) {
                    errorsCount = 0;
                }

                for (let i = 0; i < errorsCount; i += 1) {
                    this.activitiesService.answersProgressBarMultiZone.push({
                        id: +save.get('granule')[0],
                        state: ItemAnswerStateEnum.incorrect,
                        isLast: false
                    });
                }

                this.activitiesService.answersProgressBarMultiZone.push({
                    id: +save.get('granule')[0],
                    state: ItemAnswerStateEnum.currentlyCorrect,
                    isLast: true
                });
            }
        }

        if (loadOnStep) {
            unseenStep = +loadOnStep;
            this.activitiesService.presentArrayElementIndex = +loadOnStep;
        }
        let isActivityNotAnsweredFounded: DataEntity;
        if (this.lessonsService.isAssignmentWithMetacognition()) {
            this.activitiesService.playScreenStatus = 0;
            this.lessonsService.lessonButtonClicked.next(true);
            // check if number of user-save equal activities in current lesson
            this.userSavesOfCurrentLesson = saves.filter((save: DataEntity) => +save.get('lesson') === +this.lessonsService.currentLesson.id);
            if (this.userSavesOfCurrentLesson.length && this.userSavesOfCurrentLesson.length === this.lessonsService.currentLesson.get('reference').length) {
                const userSaveWithNoAnswer = this.userSavesOfCurrentLesson.find((save) => save.get('created') === save.get('changed'));
                isActivityNotAnsweredFounded = userSaveWithNoAnswer ? activities.find((activity: DataEntity) => +activity.id === +userSaveWithNoAnswer.get('granule')[0]) : null;
            }

            if (!isActivityNotAnsweredFounded && this.userSavesOfCurrentLesson.length &&
                this.userSavesOfCurrentLesson.length < this.lessonsService.currentLesson.get('reference').length) {
                const userSaveCreatedByDefault = this.userSavesOfCurrentLesson.find((userSave) => userSave.get('created') === userSave.get('changed'));
                if (userSaveCreatedByDefault) {
                    isActivityNotAnsweredFounded = activities.find((activity) => +activity.id === +userSaveCreatedByDefault.get('granule')[0]);
                } else {
                    const userSaves: number[] = this.userSavesOfCurrentLesson.map((save) => +save.get('granule')[0]);
                    isActivityNotAnsweredFounded = activities.filter((activity) => userSaves.includes(+activity.id) === false)[0];
                }
            }
            if (!!isActivityNotAnsweredFounded || !this.userSavesOfCurrentLesson.length) {
                this.lessonsService.saveProgressInAssignmentWithMetacognition(false);
                unseenStep = isActivityNotAnsweredFounded ? activities.findIndex((activity: DataEntity) => +activity.id === +isActivityNotAnsweredFounded.id) : 0;
                this.activitiesService.loadActivityByStep(unseenStep, true);
            } else {
                this.lessonsService.saveProgressInAssignmentWithMetacognition(true);
                this.activitiesService.metacognition(true);
            }
            this.currentActivityPlayedIndex = unseenStep;
        } else {
            this.navigateToActivity(unseenStep, this.activatedRoute.snapshot.queryParams);
        }
        this.refreshStepper();
        this.isLoading = false;
    }

    /**
     * set activitiesService with the lesson selected, the activities in the lesson, the step to navigate
     * @param lessonEntity
     * @param loadOnStep
     * @param {any} usersaves
     * @param {any} alreadyLessonLoaded
     */
    processLesson(lessonEntity: LessonGranuleEntity, loadOnStep, usersaves = null, alreadyLessonLoaded = null): void {
        this.activitiesService.currentLesson = lessonEntity;
        this.lessonsService.currentLesson = lessonEntity;
        if (lessonEntity.get('metadatas')) {
            this.lessonTitle = lessonEntity.get('metadatas').title;
        }
        this.activitiesService.pushLessonFromAssignment.next(lessonEntity);
        const activitiesArray = this.activitiesService.getPropertyFromNestedObject(lessonEntity, ['attributes', 'reference']);
        this.activitiesService.setActivitiesListWithIds(activitiesArray);
        this.currentActivity = this.activitiesService.activitiesArray[+loadOnStep ? +loadOnStep : 0];
        if (usersaves) {
            this.processActivitiesAndUserSaves(activitiesArray, usersaves, loadOnStep, alreadyLessonLoaded);
        } else {
            const currentAssignment = this.lessonsService.currentAssignment;
            if (currentAssignment || (!currentAssignment && this.lessonsService.isAtLeastParent())) {
                const activityObservable = this.lessonsService.loadLessonActivities(lessonEntity);
                const userSaveObservable = currentAssignment ? this.lessonsService.loadUserSaves(currentAssignment.id.toString(), currentAssignment.get('assignated_user').uid) : of(null);
                combineLatest([activityObservable, userSaveObservable]).pipe(
                    takeUntil(this.unsubscribeInTakeUntil),
                    tap(([activityEntities, userSaveEntities]) => this.processActivitiesAndUserSaves(activityEntities, userSaveEntities, loadOnStep))
                ).subscribe();
            } else {
                if (this.lessonsConfigurationService.shouldExitIfNoAssignment()) {
                    this.exitLesson();
                } else {
                    // Je ne comprends pas l'interet, le module assignation devrait préparer l'assignation avant de lancer le player de parcours, c'est pas au module activities de gérer ça
                    this.lessonsService.launchLessonAutoAssignment(lessonEntity, null, null, loadOnStep);
                }
            }
        }

    }

    /**
     * change from full-screen or revert to not full-screen mode
     */
    public toggleFullscreen(): void {
        this.fullscreenService.toggleFullscreen();
    }

    /**
     * exit lesson if modal confirm is need open it before to ask confirmation
     */
    public goBack(): void {
        if (this.lessonsConfigurationService.getIsModalConfirmBeforeCloseExo()) {
            this.openConfirmExitModal();
        } else {
            this.exitLesson();
        }
    }

    /**
     * Obtains the list of Plugins of type tools
     */
    public getToolsPlugins(): { toolIdentifier: string; setting: PluginSetting }[] {
        return this.genericPluginsService.getPluginsByType(PluginType.lessonTool);
    }

    /**
     * Execute the tool from the service and give it the lesson and the step for allow the service to reload the lesson at the current step.
     * @param tool
     */
    public executeTool(tool: { toolIdentifier: string; setting: PluginSetting }): void {
        this.lessonsService.executeToolFromLesson(tool, {lesson: this.lessonsService.currentLesson, step: this.activitiesService.presentArrayElementIndex}).pipe(
            take(1)
        ).subscribe();
    }

    /**
     * if arrow navigation enable, navigate to next activity
     */
    public nextActivity(): void {
        if (this.activitiesService.activitiesArray[+this.activitiesService.presentArrayElementIndex + 1]) {
            this.onloadActivityAsCurrent.next(this.activitiesService.activitiesArray[+this.activitiesService.presentArrayElementIndex + 1]);
        }
    }

    /**
     * if arrow navigation enable, navigate to previous activity
     */
    public previousActivity(): void {
        if (this.activitiesService.activitiesArray[+this.activitiesService.presentArrayElementIndex - 1]) {
            this.onloadActivityAsCurrent.next(this.activitiesService.activitiesArray[+this.activitiesService.presentArrayElementIndex - 1]);
        }
    }

    /**
     * if arrow navigation enable, show arrow for navigation
     * @param action
     */
    public isArrowNavigationEnabled(action: string): boolean {
        const roles = this.lessonsConfigurationService.isArrowNavigationEnabled();
        if (action === 'next') {
            return roles.length && roles.includes(this.authService.accessLevel)
                && !!this.activitiesService.activitiesArray[+this.activitiesService.presentArrayElementIndex + 1];
        }
        if (action === 'previous') {
            return roles.length && roles.includes(this.authService.accessLevel)
                && !!this.activitiesService.activitiesArray[+this.activitiesService.presentArrayElementIndex - 1];
        }

        return false;
    }

    /**
     * open help modal or feedback modal ( help is launch by button feedback is launch in some exo on some bad answer (not all)
     * @param feedBack : optionnal feedback replace the default value is use when bad answer is link with a feedback
     */
    public help(feedBack: string = null, title?: string, callback?: () => Observable<DataEntity>): void {
        // if whe use error message that mean help is not open by button but by communication center
        // after having a bad answer who have a comment associate
        const feedbackMsg = feedBack ? feedBack : this.getFeedBack();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: title ? title : 'activities.title_modal_help',
            labelTrueDialog: 'activities.title_modal_understood',
            enableTTS: this.lessonsConfigurationService.getEnableTTS()
        };
        this.translate.get(dialogConfig.data.titleDialog).subscribe((translation: string) => dialogConfig.data.titleDialog = translation);
        this.translate.get(dialogConfig.data.labelTrueDialog).subscribe((translation: string) => dialogConfig.data.labelTrueDialog = translation);

        dialogConfig.data.bodyDialog = '<span>' + feedbackMsg + '</span>';
        dialogConfig.panelClass = 'help_close_modal'; // make modal more from bottom not centered
        dialogConfig.backdropClass = 'backdrop-blur';
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);

        if (this.lessonsConfigurationService.getActivitiesBroadcastLifeCycle()) {
            // Keep track of the presentation of the help modal
            this.communicationCenter
                .getRoom('lrs')
                .getSubject('activity_open')
                .next({id: `help/${this.currentActivity.id}`, assignmentId: this.lessonsService.currentAssignment?.id || null});
        }

        dialogRef.afterClosed().subscribe(() => {
            callback();
        });
    }

    /**
     * get feedback in regard of where he is if it's a lesson or not
     */
    public getFeedBack(): string {
        try {
            let feedBack = '';
            if (this.currentActivity.get('format').label === 'lesson') {
                if (!this.lessonsService.currentActivityInSubLesson && this.lessonsService.currentActivityInSubLesson.get('reference').feedback) {
                    throw Error('not feedback inside lesson found');
                }
                feedBack = this.lessonsService.currentActivityInSubLesson.get('reference')?.feedback;
            } else {
                feedBack = this.currentActivity.get('reference').feedback;
            }
            return feedBack;
        } catch (ex) {
            return '';
        }
    }

    /**
     * number of exercices to do
     */
    public totalOfExercises(): number {
        if (this.lessonsService.isLessonHasSubLesson(this.lessonsService.currentLesson)) {
            return this.lessonsService.subLessonContentEdited ? this.lessonsService.subLessonContentEdited.length : 0;
        } else {
            return this.lessonsService.currentLesson && this.lessonsService.currentLesson.get('reference').length;
        }
    }

    public showGenericProgressBar(): boolean {
        return this.lessonsConfigurationService.isGenericProgressBarActive(this.activitiesService.currentAssignment?.get('type_term')?.label);
    }

    public showMultiZoneProgressBar(): boolean {
        return this.lessonsConfigurationService.isMultiZoneProgressBarActive(this.activitiesService.currentAssignment?.get('type_term')?.label)
            && !this.barIsHidden
            && this.showHeaderExo
            && this.totalOfExercises() > 1;
    }

    private refreshStepper(): void {
        this.communicationCenter.getRoom('stepper').getSubject('locked').next(false);
        this.visitedActivity = this.visitedActivity ?? [];
        this.visitedActivity.push(+this.activitiesService.presentArrayElementIndex); // if no visited activities, set current activity as visited
        this.stepperOptions = this.stepperService.getStepperConfig(
            (activity: DataEntity, index) =>
                this.lessonsService.isAssignmentWithMetacognition()
                    ? +index === +this.currentActivityPlayedIndex
                    : +index === (this.lessonsService.subLessonContentEdited.length ? this.activitiesService.currentActivityIndex : +this.activitiesService.presentArrayElementIndex),
            this.onloadActivityAsCurrent,
            this.userSavesOfCurrentLesson,
            this.visitedActivity,
            this.userAnswered,
        );
        this.isLoading = false;
    }

    // TODO utiliser le onloadActivityAsCurrent pour mutualiser les facon de naviguer
    public get displayStepper(): boolean {
        return this.stepperOptions !== null && this.isDisplayStepper && this.stepperOptions.isListFormatStepper;
    }

    private navigateToActivity(unseenStep: number, queryParams: Params = {}, retry?: boolean): void {
        if (this.activitiesService.playScreenStatus === 1) {
            this.lessonsService.lessonButtonClicked.next(true);
            this.activitiesService.playScreenStatus = 0;
            if (retry) {
                if (this.currentActivity.get('format').label === 'lesson') {
                    this.activitiesService.presentArrayElementIndex = unseenStep;
                } else {
                    unseenStep = 0;
                    this.activitiesService.presentArrayElementIndex = 0;
                }
            }
            if (unseenStep < this.lessonsService.currentLesson.get('reference').length) {
                if (!retry) {
                    ++this.activitiesService.presentArrayElementIndex;
                }
                this.refreshStepper();
                this.activitiesService.loadActivityByStep(unseenStep, !retry, queryParams);
            } else {
                this.lessonsService.loadRecapScreen();
            }
        }
    }

    /**
     * update the current activity and set title
     */
    private updateActivity(): void {
        this.currentActivity = this.activitiesService.getCurrentActivity(this.activitiesService.presentArrayElementIndex);
        if (this.currentActivity) {
            if (this.currentActivity.get('metadatas').typology && this.currentActivity.get('metadatas').typology.label === 'video') {
                this.activityTitle = this.currentActivity.get('reference').activity_content[0].granule[0].metadatas['title'];
            } else {
                this.activityTitle = this.currentActivity.get('metadatas').title;
            }
        }
    }

    /**
     * listen all event that change the status of progress bar:
     * number of question of the current activity
     * answer made state : true or false
     * reset answers to do when change of activity
     * all event are send from exercices to here
     * @private
     */
    private listenProgressBarExoEvent(): void {
        // number of question for the current exercice
        this.communicationCenter.getRoom('progress-bar-exo')
            .getSubject('numberOfQuestion')
            .subscribe(res => {
                this.numberOfQuestion = res;
            });

        this.communicationCenter.getRoom('activities')
            .getSubject('exitLesson')
            .subscribe((res) => {
                if (res === true) {
                    this.exitLesson();
                }

            });

        // state of last answer is a true or is a false answer
        this.communicationCenter.getRoom('progress-bar-exo')
            .getSubject('answerResult')
            .pipe(filter((res: boolean) => !!res))
            .subscribe(() => {
                this.answersCopy = this.activitiesService.answersProgressBarMultiZone.slice();
            });
        // reset answers state
        this.communicationCenter.getRoom('progress-bar-exo').getSubject('reset').subscribe(res => {
            this.activitiesService.answersProgressBarMultiZone = [];
            this.answersCopy = [];
        });

        // hide or show
        this.communicationCenter.getRoom('progress-bar-exo').getSubject('hide')
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(res => {
                this.barIsHidden = res;
            });
    }

    /**
     * when navigate in exo if we close we want to come back to previous concept listing
     * header with close button is here so we store it in case of closing exo
     */
    private listenLastConceptSelected(): void {
        this.communicationCenter.getRoom('concept').getSubject('lastConceptId').pipe(
            takeUntil(this.unsubscribeInTakeUntil)
        ).subscribe(id => {
            this.previousConceptIdList = id;
        });
    }

    /**
     * move from ngOninit for beeing able to launch it only
     * most of content are subscribing content
     */
    private initSubscribes(): void {
        // TODO check if no regressions vith MB
        if (!this.lessonsService.currentAssignment && this.lessonsService.getAllowedRolesForAutoAssignmentCreation().includes(this.authService.accessLevel) === false) {
            this.router.navigate([defaultLoginRoute]);
        } else {
            this.router.events.pipe(filter(event => event instanceof NavigationEnd))
                .subscribe((urlSegment) => {
                    this.stepTitle = this.lessonStep ? this.lessonStep.typeSteps[this.activitiesService.presentArrayElementIndex].label : '';
                });

            this.lessonsService.lessonButtonClicked.pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe(value => {
                    this.userAnswered = this.activitiesService.activityAnswerResult;
                    this.setActivityVisitInfo();
                });

            this.loadLessonById(this.activatedRoute.snapshot.params['lessonId'], this.activatedRoute.snapshot.queryParams['startOnStepIndex']);

            this.activitiesService.activityActionsHandler.pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe(data => {
                    if (data['resetAll']) {
                        this.activitiesService.unsetAnswerTempSave();
                        this.userAnswered = this.activitiesService.activityAnswerResult;
                    }
                });

            this.activitiesService.onLatexKeyboardDisplayChange.subscribe((isDisplayed: boolean) => {
                this.isLatexKeyboardDisplayed = isDisplayed;
            });
            this.onloadActivityAsCurrent.pipe(filter(() => !this.isLoading)).subscribe(stepItemActivity => {
                this.isLoading = true;
                if (stepItemActivity !== null) {
                    if (this.lessonsService.subLessonContentEdited.length) {
                        this.communicationCenter
                            .getRoom('activities')
                            .getSubject('gotoSelectedActivity')
                            .next(stepItemActivity);
                        this.isLoading = false;
                    } else {
                        // check if we can navigate in previous activity in the stepper
                        if (this.canNavigateToStep(stepItemActivity)) {
                            this.currentActivity = stepItemActivity;
                            this.activitiesService.presentArrayElementIndex = +(this.activitiesService.activitiesArray.indexOf(stepItemActivity)).toString();
                            this.gotoCurrentPage(this.activitiesService.activitiesArray.indexOf(stepItemActivity), this.activatedRoute?.snapshot?.queryParams);
                        }
                    }
                } else {
                    this.gotoEndPage();
                }
            });
        }

        this.lessonsService.activityIdChange.pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(() => {
            this.updateActivity();
            this.refreshStepper();
        });

    }

    /**
     * reset all data and state
     */
    private reset(resetAssignment: boolean): void {
        this.activitiesService.clearLessonState(resetAssignment);
        this.userAnswered = [];
        this.lessonTitle = '';
        this.activityTitle = '';
        this.stepTitle = '';
        this.userAnswered = [];
        this.isLoading = false;
        this.learnerInfo = null;
        this.isLatexKeyboardDisplayed = false;
        this.onloadActivityAsCurrent = new Subject<DataEntity>();
        this.stepperOptions = null;
        this.visitedActivity = [];
        this.unSeenFirst = null;
    }

    /**
     * Reset data, load lesson, activities, saves, estimate the last unseen activity and set this activity has current
     * @param lessonId
     * @param loadOnStep index of activity to set as current for overwrite the default current activity (first unseen activity that can be deduced by the absence of save)
     */
    private loadLessonById(lessonId: string, loadOnStep?: string): void {
        if (this.activitiesService.activitiesArray.length === 0) {
            this.activitiesService.playScreenStatus = 1;
            this.activitiesService.presentArrayElementIndex = 0;
            this.activitiesService.activityAnswerResult = [];
            this.userAnswered = this.activitiesService.activityAnswerResult;
            this.isLoading = true;
            this.lessonsService.loadLessonGranuleById(lessonId).pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((lessonEntity) => {
                    this.processLesson(lessonEntity, loadOnStep);
                });
        } else {
            if (this.activitiesService.currentLesson) {
                this.lessonTitle = this.activitiesService.currentLesson.get('metadatas').title;
            }
        }
    }

    /**
     * open modal to confirm exit
     * if confirm then exit else stay here
     */
    private openConfirmExitModal(): void {

        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: 'activities.title_modal_stop',
            labelTrueDialog: 'activities.title_modal_quit',
            labelFalseDialog: 'activities.title_modal_cancel',
            bodyDialog: 'activities.title_modal_content',
            trueSvgDialog: 'quit',
            falseSvgDialog: 'next',
            hideCloseBtn: true
        };

        this.translate.get(dialogConfig.data.titleDialog).subscribe((translation: string) => dialogConfig.data.titleDialog = translation);
        this.translate.get(dialogConfig.data.labelTrueDialog).subscribe((translation: string) => dialogConfig.data.labelTrueDialog = translation);
        this.translate.get(dialogConfig.data.labelFalseDialog).subscribe((translation: string) => dialogConfig.data.labelFalseDialog = translation);
        this.translate.get(dialogConfig.data.bodyDialog).subscribe((translation: string) => dialogConfig.data.bodyDialog = translation);

        dialogConfig.panelClass = 'help_close_modal'; // make modal more from bottom not centered
        dialogConfig.backdropClass = 'backdrop-blur';
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                //ctz-settings
                if (this.lessonsConfigurationService.getExitLessonAfterConfirm()) {
                    this.exitLesson();
                } else {
                    this.launchUserReviewForm();
                }

            }
        });
    }

    private launchUserReviewForm(): void {
        if (this._platform.ANDROID || this._platform.IOS) {
            this.isMobile = true;
        }
        this.communicationCenter.getRoom('authentication').getSubject('userData').subscribe(
            (userData) => {
                const rolesArray = userData.get('role');
                let userRole = '';
                if (rolesArray.includes(3)) {
                    userRole = 'administrator';
                }
                if (rolesArray.includes(4)) {
                    userRole = 'manager';
                }
                if (rolesArray.includes(5)) {
                    userRole = 'trainer';
                }
                if (rolesArray.includes(6)) {
                    userRole = 'learner';
                }
                // display content score modal
                const now = new Date();
                const localStorageDate = localStorage.getItem('user_review_ask');

                // Check if user already answered the question
                if (
                    (!localStorageDate || localStorageDate !== now.toDateString()) // localStorage ok
                    && this.userReviewSetting.length > 0 // NPS available for some roles
                    && this.lessonsService.currentLesson.get('allow_user_review') === true // granule ok
                ) {
                    this.userReviewService.getUserReview(userData.id, +this.lessonsService.currentLesson.id).subscribe(
                        (result) => {
                            if (
                                userRole
                                && result.entities.length === 0 // user never answered for this lesson
                                && this.userReviewSetting.includes(userRole) // setting ok
                                && userData.get('displayUserReview') // back ok
                            ) {
                                if (this.isMobile) { // if mobile, display in bottom sheet
                                    this._bottomSheetRef = this._bottomSheet.open(UserReviewComponent, {
                                        data: {
                                            itemToReview: this.lessonsService.currentLesson,
                                            uid: userData.id,
                                            type: 'bottomSheet'
                                        },
                                        panelClass: 'user-review-bottom-sheet-wrapper',
                                    });
                                    this._bottomSheetRef.afterDismissed().subscribe(result => {
                                        localStorage.setItem('user_review_ask', now.toDateString());
                                        this.exitLesson();
                                    });
                                } else { // else display in dialog
                                    this.dialogNpsRef = this.dialog.open(UserReviewComponent, {
                                        data: {
                                            itemToReview: this.lessonsService.currentLesson,
                                            uid: userData.id,
                                            type: 'dialog'
                                        },
                                        panelClass: 'user-review-dialog-wrapper',
                                    });
                                    this.dialogNpsRef.afterClosed().subscribe(result => {
                                        localStorage.setItem('user_review_ask', now.toDateString());
                                        this.exitLesson();
                                    });
                                }
                            } else {
                                this.exitLesson();
                            }
                        }
                    );
                } else {
                    this.exitLesson();
                }
            }
        );
    }

    /**
     * exit lesson
     * Attention, si tu dois rajouter une condition pour définir la route de retour : Le module ne doit pas déduire son contexte d'exécution. S'il est exécuté depuis un autre module, ce dernier devient le module parent et activities le module enfant. En tant que parent, c'est à lui de définir le contexte explicitement. C'est donc au parent de définir la route de retour (voir exitLessonUrl) et donc aucune autre condition ne doit être ajoutée ici.
     */
    private exitLesson(forceRouting: string[] = [], queryParams: Params = {}): void {
        // reset exit is done
        this.communicationCenter.getRoom('activities')
            .getSubject('exitLesson').next(false);

        if (this.fullscreenService.isFullScreenActive) {
            this.toggleFullscreen();
        }
        if (forceRouting?.length > 0) {
            this.router.navigate(forceRouting, {queryParams});
        } else if (this.lessonsService.exitLessonUrl && this.lessonsService.exitLessonUrl.length > 0) {
            const backRoute = this.lessonsService.exitLessonUrl;
            this.lessonsService.exitLessonUrl = '';

            // Il faut regénérer les query params s'il y en a dans backRoute
            // ca peut ressembler à ça ?something=1&something2=2
            // ainsi il faut récupérer ce qui suit le ?, puis le splitter sur les & et les = pour généner les queryParams au bon format
            // Le bon format etant quelque chose comme {something: 1, something2: 2}
            const [route, ...rawParams] = backRoute.split('?');
            // Je ne sais pas comment c'est géré, mais s'il y avait d'autre "?" dans les query params on les remet
            const eachParams = rawParams.join('?').split('&');
            const params = eachParams
                .map(eachParams => eachParams.split('='))
                .reduce((acc, [key, value]) => {
                    const decodedValue = decodeURIComponent(value);
                    return {...acc, [key]: decodedValue};
                }, {});


            this.router.navigate([route], {queryParams: _.merge({}, params, queryParams)});
        } else {
            // Attention, si tu dois rajouter une condition pour définir la route de retour il faut lire le commentaire de la méthode
            this.router.navigate(['/']);
        }
        // Ubolino case : the player exits the activity. Suspends the lrs tracking
        if (this.lessonsConfigurationService.getActivitiesBroadcastLifeCycle()) {
            // pour les traces, si pas de questionset, on envoie l'id du parcours parent
            let id = this.lessonsService.currentLesson.id;
            if (this.currentActivity?.get('format')?.label === 'lesson') {
                id = this.currentActivity.id;
            }
            this.communicationCenter
                .getRoom('lrs')
                .getSubject('activity_suspend')
                .next({id: `questionSet/${id}`, assignmentId: this.lessonsService.currentAssignment?.id || null});
        }

        this.lessonsService.resetActivitiesArray();
        this.activitiesService.resetActivitiesInCache();
    }

    /**
     * send information that an exercices is launch and default footer must be visible
     * @private
     */
    private defaultFooterVisible(visible: boolean): void {
        this.communicationCenter
            .getRoom('footer')
            .next('visibility', visible);
    }

    public getStepTitlePrefixPosition(): boolean {
        return this.lessonsConfigurationService.getStepTitlePrefixPosition();
    }
}