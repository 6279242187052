// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  background: #002940;
  padding-bottom: 10px;
}
.container h1, .container p {
  color: white;
  text-align: center;
  max-width: 280px;
}
.container .background-picture {
  position: relative;
  max-height: 150px;
  z-index: 9999;
}
.container .before {
  position: absolute;
  left: 0;
  top: 80px;
}
.container .after {
  position: absolute;
  right: 0;
  top: 220px;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/footer/footer.component.scss","webpack://./../APP%20Humanum/src/@fuse/components/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,oBAAA;ACCJ;ADCI;EACI,YAAA;EACA,kBAAA;EACA,gBAAA;ACCR;ADEI;EACI,kBAAA;EACA,iBAAA;EACA,aAAA;ACAR;ADGI;EACI,kBAAA;EACA,OAAA;EACA,SAAA;ACDR;ADII;EACI,kBAAA;EACA,QAAA;EACA,UAAA;ACFR","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    align-items: center;\n    background: #002940;\n    padding-bottom: 10px;\n\n    h1, p {\n        color: white;\n        text-align: center;\n        max-width: 280px;\n    }\n\n    .background-picture {\n        position: relative;\n        max-height: 150px;\n        z-index: 9999;\n    }\n\n    .before {\n        position: absolute;\n        left: 0;\n        top: 80px;\n    }\n\n    .after {\n        position: absolute;\n        right: 0;\n        top: 220px;\n    }\n\n}",".container {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  align-items: center;\n  background: #002940;\n  padding-bottom: 10px;\n}\n.container h1, .container p {\n  color: white;\n  text-align: center;\n  max-width: 280px;\n}\n.container .background-picture {\n  position: relative;\n  max-height: 150px;\n  z-index: 9999;\n}\n.container .before {\n  position: absolute;\n  left: 0;\n  top: 80px;\n}\n.container .after {\n  position: absolute;\n  right: 0;\n  top: 220px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
