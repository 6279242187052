import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FlashcardAttribute} from 'shared/flashcard';

@Component({
    selector: 'app-voice-recording-modal',
    templateUrl: './voice-recording-modal.component.html',
    styleUrls: ['./voice-recording-modal.component.scss']
})
export class VoiceRecordingModalComponent {

    constructor(
        private matDialogRef: MatDialogRef<VoiceRecordingModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FlashcardAttribute
    ) {
    }

    public close(): void {
        this.matDialogRef.close();
    }
}