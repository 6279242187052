import {NgModule} from '@angular/core';
import {GettingStartedComponent} from './getting-started.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {SharedTranslateModule} from '../../../app/shared/shared-translate.module';
import {SharedModule} from '../../../app/shared/shared.module';
import {BasicPageModule} from 'fuse-core/components/basic-page/basic-page.module';

@NgModule({
    declarations: [
        GettingStartedComponent
    ],
    imports: [
        MatButtonModule,
        MatIconModule,
        RouterModule,
        FuseSharedModule,
        BasicPageModule,
        SharedTranslateModule
    ],
    exports: [
        GettingStartedComponent
    ]
})
export class GettingStartedModule {
}