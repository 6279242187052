import {Injectable, Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import { Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class FullscreenService {

    isFullScreenActive = false;
    isFullscreenImageActive = false;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private router: Router,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
    ) {
        this.document.addEventListener('fullscreenchange', (evt) => this.setFullscreenMode(evt));
        this.document.addEventListener('webkitfullscreenchange', (evt) => this.setFullscreenMode(evt));
        this.document.addEventListener('mozfullscreenchange', (evt) => this.setFullscreenMode(evt));
        this.document.addEventListener('MSFullscreenChange', (evt) => this.setFullscreenMode(evt));
    }

    toggleFullscreen(): void {
        if (!this.document.fullscreen) {
            this.isFullScreenActive = true;
            const elem = document.documentElement;
            const methodToBeInvoked = elem['requestFullscreen'] ||
                elem['webkitRequestFullScreen'] ||
                elem['mozRequestFullscreen'] ||
                elem['msRequestFullscreen'];

            if (methodToBeInvoked) { methodToBeInvoked.call(elem); }
        } else {
            this.isFullScreenActive = false;
            const methodToBeInvoked = document['exitFullscreen'] ||
                document['webkitExitFullscreen'] ||
                document['mozCancelFullScreen'] ||
                document['msExitFullscreen'];

            if (methodToBeInvoked) { methodToBeInvoked.call(document); }
        }
    }

    public setFullscreenMode(evt): any{
        if (this.document.fullscreen) {
            this.isFullScreenActive = true;
        } else {
            this.isFullScreenActive = false;
        }
    }

    /**
     * change from full-screen or revert to not full-screen mode
     */
    public openFullscreen(elem, e = null): void {
        if (e) {
            e.stopPropagation();
        }
        this.isFullscreenImageActive = true;
        const element = document.getElementById(elem);
        const elementHtml = element.outerHTML.replace('styles/rectangle_app/public/', '');
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '100%';
        dialogConfig.height = '100%';
        dialogConfig.panelClass = 'fullsizeImage';
        dialogConfig.data = {
            titleDialog: '',
            bodyDialog: elementHtml
        };

        this.dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);

        this.dialogRef.afterClosed().subscribe(result => {
            this.isFullscreenImageActive = false;
        });
    }
}