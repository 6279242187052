import {take} from 'rxjs/operators';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommunicationCenterService} from '@modules/communication-center';
import {AuthenticationService} from '@modules/authentication';
import {DataEntity} from 'octopus-connect';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {Router, RouterModule, Routes} from '@angular/router';
import {AssignationComponent} from './components/assignation/assignation.component';
import {FuseSharedModule} from '@fuse/shared.module';
import {SharedModule} from '../../../shared/shared.module';
import {CommonSharedModule} from '@modules/activities/core/shared-components/common-shared.module';
import {FollowedListComponent} from './components/followed-list/followed-list.component';
import {FlagService} from '../../../shared/flag.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {AssignationService} from '@modules/assignation/core/assignation.service';
import {AssignmentComponentsService} from '@modules/assignation/core/assignment-components.service';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {LessonsModule} from '@modules/activities';
import {WidgetAssignmentsLessonComponent} from '@modules/assignation/core/components/widget/widget-assignments-lesson/widget-assignments-lesson.component';
import {AssignmentWarningModalComponent} from './components/assignment-warning-modal/assignment-warning-modal.component';
import {WidgetAssignmentsLearnersWithoutActiveAssignmentComponent} from './components/widget/widget-assignments-learners-without-active-assignment/widget-assignments-learners-without-active-assignment.component';
import {FollowedTabsComponent} from './components/followed-tabs/followed-tabs.component';
import {FollowedLogBookComponent} from './components/followed-log-book/followed-log-book.component';
import {SearchFiltersModule} from 'fuse-core/components';
import {FollowedFilterComponent} from './components/followed-filter/followed-filter.component';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {NoAssignmentForLongTimeAgoNewsComponent} from './components/no-assignment-for-long-time-ago-news/no-assignment-for-long-time-ago-news.component';
import { AssignmentAdaptativePopinComponent } from './components/assignment-adaptative-popin/assignment-adaptative-popin.component';
import {CustomMatPaginatorIntl} from 'fuse-core/matPaginatorI18n';
import {MyAssignmentsComponent} from './pages/my-assignments/my-assignments.component';
import {NoAssignmentComponent} from './components/no-assignment/no-assignment.component';
import { AssignmentAsButtonListComponent } from './components/assignment-as-button-list/assignment-as-button-list.component';

const routes: Routes = [
    {
        path: 'followed',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'my-assignments',
                component: MyAssignmentsComponent
            },
            {
                path: 'list',
                component: FollowedListComponent
            },
            {
                path: 'tab',
                component: FollowedTabsComponent
            },
            {
                path: 'assignment',
                children: [
                    {
                        path: '',
                        loadChildren: () => LessonsModule
                    }
                ]
            },
            {
                path: '**',
                pathMatch: 'full',
                redirectTo: 'list'
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        SharedModule,
        CommonSharedModule,
        NgxMaterialTimepickerModule,
        MatProgressSpinnerModule,
        SearchFiltersModule,
        MatDatepickerModule,
        MatMomentDateModule
    ],
    declarations: [
        AssignationComponent,
        FollowedListComponent,
        WidgetAssignmentsLessonComponent,
        AssignmentWarningModalComponent,
        WidgetAssignmentsLearnersWithoutActiveAssignmentComponent,
        FollowedTabsComponent,
        FollowedLogBookComponent,
        FollowedFilterComponent,
        NoAssignmentForLongTimeAgoNewsComponent,
        AssignmentAdaptativePopinComponent,
        MyAssignmentsComponent,
        NoAssignmentComponent,
        AssignmentAsButtonListComponent
    ],
    exports: [
        RouterModule,
        WidgetAssignmentsLessonComponent,
        WidgetAssignmentsLearnersWithoutActiveAssignmentComponent
    ],
    providers: []
})
export class AssignationModule {

    constructor(
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private assignationService: AssignationService,
        private router: Router
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Assignment',
                type: 'NEW_ASSIGNATION',
                icon: 'activity',
                text: 'assignment.notification_new_assignment',
                textTransform: (text: string, data: Object) => {
                    return {
                        userName: data['userName'],
                        formName: data['formName']
                    };
                },
                action: (data: Object) => {
                    this.assignationService.loadAndGetAssignmentById(data['id']).pipe(
                        take(1))
                        .subscribe((assignment: DataEntity) => {
                            this.assignationService.launchAssignment(assignment);

                            const node = assignment.get('assignated_node');
                            const route = [];

                            if (data['project']) {
                                route.push(...['projects', data['project'], 'tools']);
                            }

                            route.push(...['followed', 'assignment', `${node.type}s`, node.id, 'player']);

                            this.router.navigate(route);
                        });
                }
            });

        this.communicationCenter
            .getRoom('assignation')
            .next('widgetAssignmentsLessonComponent', WidgetAssignmentsLessonComponent);

        this.communicationCenter
            .getRoom('assignation')
            .next('WidgetAssignmentsLearnersWithoutActiveAssignmentComponent', WidgetAssignmentsLearnersWithoutActiveAssignmentComponent);
    }


    static forRoot(): ModuleWithProviders<AssignationModule> {

        return {
            ngModule: AssignationModule,
            providers: [
                AssignationService,
                AssignmentComponentsService,
                FlagService,
                {
                    provide: MatPaginatorIntl,
                    useClass: CustomMatPaginatorIntl
                }
            ]
        };
    }

    private postLogout(): void {
        // TODO menu
        // this.dynamicNavigation.clearMenuItem('level0', 'followed');
        // this.dynamicNavigation.clearMenuItem('level0', 'followed-tab');
    }

    private postAuthentication(): void {
        // TODO menu
            // this.dynamicNavigation.registerModuleMenu('level0', {
            //     'id': 'followed',
            //     'title': 'Assignations',
            //     'translate': 'assignment.title',
            //     'type': 'item',
            //     'icon': 'assignment',
            //     'url': '/followed'
            // });
            //
            // this.dynamicNavigation.registerModuleMenu('level0', {
            //     'id': 'followed-tab',
            //     'title': 'Assignations',
            //     'translate': 'assignment.title',
            //     'type': 'item',
            //     'icon': 'assignment',
            //     'url': '/followed/tab'
            // });
    }
}