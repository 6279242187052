import {mergeMap, take} from 'rxjs/operators';
import {ChangeDetectorRef, Component, inject} from '@angular/core';
import {combineLatest, of} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {VideoMarkersComponent} from '@modules/activities/core/editor-components/video-editor/video-markers/video-markers.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import * as _ from 'lodash-es';
import {BaseActivityComponent} from '../base-activity.component';
import {VideoActivityGranule} from '@modules/activities/core/models/activities/typologies/video-activity.granule';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html'
})
export class VideoComponent extends BaseActivityComponent<VideoActivityGranule> {
    public isInitializing: boolean;
    public markers: any[];
    public referenceActivityGranule: any;
    public selectedMarker: any;
    public userSave: DataEntity;
    public videoConfig: any;
    public media: any;

    private dialogRef: MatDialogRef<VideoMarkersComponent>;

    private videoCorpus: any;

    private changeDetector = inject(ChangeDetectorRef)
    private dialog = inject(MatDialog)
    private octopusConnect = inject(OctopusConnectService)


    public saveUserNotes(selectedMarker: any, data: any): Observable<DataEntity> {
        const index = this.markers.findIndex((marker) => marker.id === selectedMarker.id);
        const answers =
            this.userSave && this.userSave.get('userActivity').entitySave.answers.length > 0
                ? this.userSave.get('userActivity').entitySave.answers
                : this.markers.map((marker) => ' ');

        let answersEntity;
        let obs;

        if (answers && answers[index] && !answers[index].id) {
            answers[index] = data['survey-notes'];
            obs = this.saveAnswerMarkers(answers).pipe(take(1));
        } else {
            answersEntity = answers.map((answer, key) => {
                const entity = new DataEntity(
                    'answer',
                    answer,
                    this.octopusConnect,
                    answer.id
                );
                if (+key === +index) {
                    entity.set('answer', data['survey-notes']);
                }
                return entity;
            });

            obs = this.saveAnswerMarkers(answersEntity, true).pipe(take(1));
        }

        return obs;
    }

    public saveAnswerMarkers(answers, isEntities = null): Observable<DataEntity> {
        const obsAnswer = answers.flatMap((answer) => {
            if (isEntities) {
                if (this.lessonsService.isLessonTest()) {
                    return this.activitiesService.saveAnswer({answer: answer.get('answer')}, 'answer');
                } else {
                    return answer.save();
                }
            } else {
                return this.activitiesService.saveAnswer({answer: answer}, 'answer');
            }
        });
        this.isSaving = true;
        return combineLatest(...obsAnswer).pipe(
            take(1),
            mergeMap((entities: DataEntity[]) => {
                const obs = this.activitiesService
                    .saveUserSave(this.activity.id.toString(),
                        this.activitiesService.currentAssignment ? this.activitiesService.currentAssignment.id.toString() : null,
                        entities ? entities.map((entity) => entity.id.toString()) : [],
                        null, 'qcm-save',
                        this.userSave,
                        this.activityStepIndex);
                obs.subscribe((data) => {
                    this.userSave = data;
                    this.isSaving = false;
                });
                return obs;
            }));
    }

    protected setContentData(activityAttributes): void {
        this.activityType = activityAttributes.metadatas.typology.label;
        this.referenceActivityGranule = activityAttributes.reference;
        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = this.referenceActivityGranule.wording;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.media = this.referenceActivityGranule.activity_content[0].media;
        this.videoCorpus = this.getGranule(this.referenceActivityGranule.activity_content[0]);
        this.markers = this.referenceActivityGranule.activity_content[0].marker;

        if (this.videoCorpus) {
            if (this.markers && this.markers.length) {
                if (!this.isActivityEmbedded) {
            this.loadUserSave();
        }
                this.activityStepIndex = this.lessonsService.activityIndex;
            }
            this.videoConfig = {
                config: {
                    src: this.videoCorpus.get('reference').url
                },
                canEditMarker: false,
                markers: this.markers,
                overlayClicked: (marker) => this.openSelectedMarker(marker)
            };
            if (this.activitiesService.settings.urlVideoException.some((urlException) => this.videoCorpus.get('reference').url.includes(urlException))) {
                this.videoConfig.config['type'] = 'video/mp4';
            }
            if (this.videoCorpus.get('format').label === 'video' && this.videoCorpus.get('reference').filemime === 'video/mp4') {
                this.videoConfig['config'] = {
                    src: this.videoCorpus.get('reference').uri,
                    type: this.videoCorpus.get('reference').filemime
                };
            }
        }

        this.isInitializing = false;
    }

    /**
     * Extract the granule's DataEntity from the given activityContent data
     * @param activityContent - field from activity
     * @returns granule from the content as a DataEntity
     */
    private getGranule(activityContent: any): DataEntity {
        if (activityContent && activityContent.granule && activityContent.granule.length) {
            const granule = activityContent.granule[0];
            return new DataEntity('granule', granule, this.octopusConnect, granule.id);
        }
        return null;
    }

    /**
     * Resets the current component
     */
    protected reset(resetAllSubscribe: boolean = false, type: string = null): Observable<boolean> {
        this.isInitializing = true;
        delete this.referenceActivityGranule;
        delete this.videoCorpus;
        delete this.videoConfig;
        this.changeDetector.detectChanges();
        return super.reset(resetAllSubscribe, type);
    }

    private openSelectedMarker(selectedMarker): void {
        const index = this.markers.findIndex((marker) => marker.id === selectedMarker.id);
        const answer = this.userSave ? this.userSave.get('userActivity').entitySave.answers[index] : '';
        this.selectedMarker = selectedMarker;
        this.dialogRef = this.dialog.open(VideoMarkersComponent, {
            panelClass: 'player-video-dialog',
            data: {
                lesson: this.lessonsService.currentLesson.get('metadatas').title,
                markerType: selectedMarker.marker_type ? selectedMarker.marker_type : {label: 'activities.personalize'},
                marker: selectedMarker,
                isEditor: false,
                videoConfig: _.cloneDeep(this.videoConfig),
                answer: answer,
                callback: (marker, data) => this.saveUserNotes(marker, data)
            }
        });

        this.dialogRef.afterClosed().subscribe((result) => {

            if (result) {
                if (this.isSaving && result) {
                    this.userSave = result;
                    this.isSaving = false;
                }
            }

        });
    }

    protected saveAnswer(): Observable<any> {
        return of(this.markers.map(() => ''));
    }

    protected setAnswer(): void {
        if (this.userSave && this.userSave.get('state') !== 'incomplete') {
            this.activitiesService.userAnswer.next(this.userSave.get('userActivity').entitySave.answers);
        }
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected  getGrade(): {oldGrade: number, newGrade: number} {
        throw new Error('Method not implemented.');
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}