export const cards = {
    cardDisplay: {
        showButtonThemeLesson: false,
        showButtonBookmarks: {
            themes: false,
            lesson: false,
            model: false,
            community: false,
            image: false,
            video: false,
            videoUrl: false,
            audio: false,
            document: false,
            url: false,
        },
        isTextButton: false,
    },
    cardDefaultHeader: '/assets/marqueblanche/images/backgrounds/header.png',
    cardFields: {
        default: {
            lesson: ['title', 'menu', 'dynamicLevelTypeImage', 'educationalLevel', 'description', 'assignation_type'],
            model: ['title', 'menu', 'dynamicLevelTypeImage', 'educationalLevel', 'description', 'assignation_type'],
            community: ['title', 'menu', 'dynamicLevelTypeImage', 'educationalLevel', 'description', 'assignation_type'],
            image: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            video: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            videoUrl: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            audio: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            document: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            url: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
        }
    },
    cardPlayOpenMenu: false,
    menuLinks: {
        lesson: {
            manager: [
                'editAndDeleteAssignments',
                'deleteWithAssignments',
                'duplicate'
            ],
            default: [
                'editAndDeleteAssignments',
                'deleteWithAssignments',
                'assign',
                'duplicate'
            ]
        },
        model: {
            manager: [
                'editAndDeleteAssignments',
                'deleteWithAssignments',
                'duplicate'
            ],
            default: [
                'assign',
                'duplicate'
            ]
        }
    },
    addExtraDataUrl: false,//citizen-code setting default false
    navigateOnInit: true,//citizen-code setting default true
    focusOnFileAfterViewInit: false,//citizen-code setting default false
    useCtzBackUrlLogic: false,//citizen-code setting default false
    usefilterUsageAutonomie: false,//citizen-code setting default false
    checkAssignableState: true,//citizen-code setting default true
    downloadLessonPdfMethodIsAllowed: true,//citizen-code setting default true
    openLessonPageListPdfAllowed: false,//citizen-code setting default false
    openCurrentPdfAllowed: false,//citizen-code setting default false
    pdfDonwnloadIcon: 'download-pdf',
    playPreviewIcon: 'play-preview',
    playMetadataIconAllowed: true,//citizen-code setting default true
    playAsIConIcon: 'play',
    playAsLabelIcon: 'generic.lesson.play',
    playAsLabelInActionMenuDynamicClass: false,//citizen-code setting default false
    buttonAssignIconConditionNotUseShowButtonAssign: false,//citizen-code setting default false
    openLessonPageUseOnlyDatacard: false,//citizen-code setting default false
    rootFilterSubscribeToSelectedValue: false,//citizen-code setting default false
    useIsLaunchLessonAskModalActiveWithoutParams: false,//citizen-code setting default false
};
